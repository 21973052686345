import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useTranslation } from "react-i18next";

// store
import { API } from '@store/config'
import { createRequest } from '@services/ServiceCommon'
// components
import { Icon, Form, Header, Button, Modal, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';

const BugReporter = () => {
    const { t } = useTranslation();
    const user = useSelector(state => state.user)
    const loc = useLocation()

    const [form, setForm] = useState({
        title: '',
        description: '',
        screenshot: '',
        user: { name: user.name, email: user.email, role: user.role_name },
        type: 0, // 0-BUG    1-IMPROVEMENT,
        path: loc.pathname,
    });

    const [files, setFiles] = useState([])

    const [active, setActive] = useState(false)
    const [modal, setModal] = useState(false)
    // eslint-disable-next-line
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    function getSubDomain (){
        let domain = window.location.href.toString()
        domain = domain.replace("www.", "")
        domain = domain.replace("https://", "")
        domain = domain.replace("http://", "")
    
        return domain.split('.')[0] 
    }

    async function handleSubmit() {
        setLoading(true)

        let subdomain = getSubDomain()
        if (subdomain !== "") subdomain = `[${subdomain}]: `

        let formData = {
            description: `### Description\n\n${form.description + "\n\n"} - User:  **${form.user.name}**\n - Email: **${form.user.email}**\n - Role: **${form.user.role}**\n\nPath: _${form.path}_`,
            title: `${subdomain}${form.title}`,
            labels: form.type === 0 ? ['Bug', 'Backlog', 'Reported'] : ['Improvement', 'Backlog', 'Reported'],
        }
        if (files.length > 0) {
            const endpoint = API.GIT_ATTACHMENTS
            formData = { ...formData, attachments: [] }

            for (let i = 0; i < files.length; i++) {
                const attachmentFormData = new FormData()
                attachmentFormData.append('file', files[i])
                const attachments = await createRequest(endpoint, attachmentFormData)
                if (attachments.status === 201) {
                    formData = { ...formData, attachments: [...formData.attachments, attachments.response.id] }
                }
            }

        }
        const request = await createRequest(API.GIT_ISSUE, formData)
        if (request.status === 201) {
            setForm({
                title: '',
                description: '',
                screenshot: '',
                user: { name: user.name, email: user.email, role: user.role_name },
                type: 0, // 0-BUG    1-IMPROVEMENT,
                path: loc.pathname
            })
            setFiles([])
            document.getElementById('screenshot').value = null
            setError(false)
        } else {
            setError(true)
        }
        setModal(true)
        setLoading(false)
    }

    return (
        <div className={`bug-reporter ${!active ? 'non-' : ''}active`}>
            <Modal
                size='mini'
                open={modal}
                onOpen={() => setModal(true)}
                onClose={() => setModal(false)}
            >
                <Modal.Header style={{ textAlign: 'center' }}>{form.type === 0 ? t('bug-sent') : t('improvement-sent')}</Modal.Header>
                <Modal.Content style={{ textAlign: 'center' }}>
                    {!error ?
                        <>
                            {form.type === 0 ? t('bug-sent-text') : t('improvement-sent-text')}
                        </>
                        :
                        <>
                            {t('error-one')}<br />
                            {t('error-two')}
                        </>}
                </Modal.Content>
            </Modal>

            <div className={`bug-reporter-box ${!active ? 'non-' : ''}active ${form.screenshot ? 'with-image' : ''}`}>
                <Form>
                    <Header
                        as='h1'
                        className={`bug-reporter-form ${!active ? 'non-' : ''}active`}
                        style={{ textAlign: 'center' }}
                        content={t('bug-reporter')}
                    />
                    <Divider />
                    <Form.Group widths="equal">
                        <SuperField
                            as="input"
                            required
                            label={t('title')}
                            value={form.title}
                            className={`bug-reporter-form ${!active ? 'non-' : ''}active`}
                            onChange={(e, { value }) => setForm({ ...form, title: value })}
                        />
                        <Form.Field style={{ display: 'flex', marginTop: "2.4rem" }}>
                            <SuperField
                                className={`bug-reporter-form ${!active ? 'non-' : ''}active`}
                                label={t('bug')}
                                checked={form.type === 0}
                                onChange={() => setForm({ ...form, type: 0 })}
                                as="radio"
                            />
                            <SuperField
                                className={`bug-reporter-form ${!active ? 'non-' : ''}active`}
                                label={t('improvement')}
                                style={{ margin: '0 1rem' }}
                                checked={form.type === 1}
                                onChange={() => setForm({ ...form, type: 1 })}
                                as="radio"
                            />
                        </Form.Field>
                    </Form.Group>
                    <SuperField
                        as="textarea"
                        rows="6"
                        className={`bug-reporter-form ${!active ? 'non-' : ''}active`}
                        label={t('description')}
                        value={form.description}
                        onChange={(e, { value }) => setForm({ ...form, description: value })}
                    />
                    <Form.Input
                        type="file"
                        multiple
                        accept=".png, .jpg, .jpeg"
                        id="screenshot"
                        name="screenshot"
                        label={t('files')}
                        onChange={(event) => {
                            if (event.target?.files?.length > 0) {
                                setFiles(event.target.files)
                            }
                        }}
                    />
                    <Divider />
                </Form>
                {/* {form.screenshot &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", marginBottom: '1rem' }}>
                            <b style={{ display: 'flex', justifyContent: 'space-between', margin: '1rem 0', alignItems: "center" }} className={`bug-reporter-form ${!active ? 'non-' : ''}active`} >
                                {t('screenshot')}
                            </b>
                            <div>
                                <Button content={t('del-screenshot')} onClick={() => setForm({ ...form, screenshot: '' })} />
                            </div>
                        </div>
                        <div className={`bug-reporter-form ${!active ? 'non-' : ''}active image-wrapper`}>
                            <img
                                src={form.screenshot}
                                alt='screen-capture' />
                        </div>
                    </>
                } */}
                <div style={{ display: 'flex', marginTop: '0.5rem' }} className={`bug-reporter-form ${!active ? 'non-' : ''}active`} >
                    <Button
                        loading={loading}
                        disabled={form.title === '' || loading}
                        primary
                        onClick={() => handleSubmit()/*handleSave()*/}
                        content={t('send-report')} />
                </div>
            </div>
            {!active ?
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Icon style={{ color: 'var(--white)', cursor: 'pointer', margin: 0 }}
                        size="large" name="bug" onClick={() => setActive(!active)} />
                </div> :
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Icon
                        style={{ color: 'var(--white)', cursor: 'pointer', margin: 0, textAlign: 'right' }}
                        size="large" name="close"
                        onClick={() => setActive(!active)} />
                </div>
            }
        </div>
    )
}
export default BugReporter