import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
// store
import { isEmpty } from '@helpers/validation'
import { requests } from '@helpers/requests'
import { API } from '@store/config'
// components
import { Form, Message } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import CustomFormDivider from '@components/general/CustomFormDivider'
import MKEditor from '@components/editors/MKEditor'

const IssueCreateForm = ({ projectParams, setData, setTotal, onClose }) => {
    const { t } = useTranslation()

    const user = useSelector((state) => state.user)
    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [form, setForm] = useState({
        name: '',
        description: '',
        dueDate: null,
        estimatedTime: null,
        group: '',
        assigned: [],
        milestone: '',
        labels: [],
    })

    const [showAdditionalDatas, setShowAdditionalDatas] = useState(true)

    const handleSubmit = async () => {
        setErrors(null)
        setIsProcessing(true)

        const result = await requests.post(API.PROJECT_ISSUES, {
            name: form.name,
            description: form.description,
            due_date: form.dueDate,
            estimated_time: form.estimatedTime,
            group: form.group,
            assigned: { add: form.assigned },
            milestone: form.milestone,
            labels: { add: form.labels },
            project: projectParams,
        })

        if (result.status === 201) {
            setData((prev) => [result.response, ...prev])
            setTotal((prev) => prev + 1)
            onClose()
        } else {
            setErrors(result.response)
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors?.non_field_errors?.length > 0}
                header={t('error_submission_message')}
                list={errors?.non_field_errors}
            />
            <SuperField
                as="input"
                required
                autoFocus
                label={t('name')}
                placeholder={t('name')}
                error={errors?.name || false}
                value={form.name}
                onChange={(e, { value }) => setForm({ ...form, name: value })}
            />

            <MKEditor
                label={t('description')}
                markdown={form.description}
                onChange={(value) => setForm((prev) => ({ ...prev, description: value }))}
            />

            <CustomFormDivider
                header={
                    <>
                        {t('additional_information')}
                        <small
                            onClick={() => setShowAdditionalDatas(!showAdditionalDatas)}
                            className="ref-link"
                            style={{ color: !showAdditionalDatas ? 'var(--primary)' : 'var(--danger)', float: 'right' }}
                        >
                            {showAdditionalDatas ? t('remove') : t('add')}
                        </small>
                    </>
                }
                divider={true}
                content={
                    <>
                        {showAdditionalDatas ? (
                            <>
                                <Form.Group widths="3">
                                    <SuperField
                                        as="choice"
                                        search
                                        text="name"
                                        label={t('milestone')}
                                        value={form?.milestone}
                                        error={errors?.milestone || false}
                                        onChange={(e, { value }) => setForm({ ...form, milestone: value })}
                                        endpoint={API.PROJECT_MILESTONES + `?project=${projectParams}`}
                                    />
                                    <SuperField
                                        as="datepicker"
                                        label={t('due_date')}
                                        placeholder={t('due_date')}
                                        error={errors?.due_date || false}
                                        value={form?.dueDate}
                                        onChange={(e, { value }) => {
                                            value === '' || value === null
                                                ? setForm({ ...form, dueDate: null })
                                                : setForm({ ...form, dueDate: value })
                                        }}
                                    />

                                    <SuperField
                                        as="input"
                                        error={errors?.estimated_time || false}
                                        label={t('estimated_time')}
                                        value={form?.estimatedTime}
                                        onChange={(e, { value }) => {
                                            value === '' || value === null
                                                ? setForm({ ...form, estimatedTime: null })
                                                : setForm({ ...form, estimatedTime: value })
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group widths="equal">
                                    <SuperField
                                        as="choice"
                                        search
                                        multiple
                                        value={form?.assigned}
                                        label={t('assigned')}
                                        error={errors?.assigned || false}
                                        endpoint={API.EMPLOYEES + `?only_basic_info=true&project=${projectParams}`}
                                        text="fullname"
                                        onChange={(e, { value }) =>
                                            setForm({
                                                ...form,
                                                assigned: value,
                                            })
                                        }
                                    />
                                    <SuperField
                                        as="choice"
                                        search
                                        multiple
                                        value={form?.labels}
                                        label={t('labels')}
                                        error={errors?.labels || false}
                                        endpoint={API.PROJECT_LABELS + `?project=${projectParams}`}
                                        text="name"
                                        onChange={(e, { value }) =>
                                            setForm({
                                                ...form,
                                                labels: value,
                                            })
                                        }
                                    />
                                </Form.Group>
                                <SuperField
                                    as="checkbox"
                                    label={t('assign_to_me')}
                                    value={user.profile_id}
                                    onChange={(e, { value }) =>
                                        setForm({
                                            ...form,
                                            assigned: [...form?.assigned, value],
                                        })
                                    }
                                    disabled={form?.assigned?.find((index) => index === user.profile_id) ? true : false}
                                    checked={form?.assigned?.find((index) => index === user.profile_id) ? true : false}
                                />
                            </>
                        ) : (
                            ''
                        )}
                    </>
                }
            />
            <Form.Field style={{ textAlign: 'right', margin: '1rem' }}>
                <ModalCancel text={t('cancel')} onClose={onClose} disabled={isProcessing} />
                <ModalSubmit text={'Create'} disabled={isProcessing || isEmpty(form.name)} loading={isProcessing} />
            </Form.Field>
        </Form>
    )
}

export default IssueCreateForm
