import React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
//components
import { Icon, Header } from 'semantic-ui-react'
import SortedDocPreview from '@components/SortedDocPreview'
import SuperDuperModal from '@components/modals/SuperDuperModal'

const SortedFileRenderer = ({ document, name, file, onClick }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)
    const splittedURI = file?.split('.')
    const fileType = (splittedURI[splittedURI.length - 1] || 'pdf').toLowerCase()

    return (
        <SuperDuperModal
            size="large"
            trigger={
                <Header as="h4" style={{ cursor: 'pointer' }}>
                    <Icon
                        size="large"
                        title={t('file')}
                        style={{ cursor: 'pointer', color: 'var(--dark)' }}
                        name={
                            fileType === 'pdf'
                                ? 'file pdf'
                                : ['docx', 'doc'].includes(fileType)
                                ? 'file word'
                                : ['xlsx', 'xls'].includes(fileType)
                                ? 'file excel'
                                : ['txt'].includes(fileType)
                                ? 'file text'
                                : ['mp4', 'avi', 'mov', 'flv'].includes(fileType)
                                ? 'file video outline'
                                : ['mp3', 'm4a', 'wav'].includes(fileType)
                                ? 'file audio outline'
                                : ['png', 'gif', 'jpg', 'jpeg'].includes(fileType)
                                ? 'file image outline'
                                : 'file alternate outline'
                        }
                        color={
                            fileType === 'pdf'
                                ? 'red'
                                : ['docx', 'doc'].includes(fileType)
                                ? 'blue'
                                : ['xlsx', 'xls'].includes(fileType)
                                ? 'green'
                                : ['txt'].includes(fileType)
                                ? 'grey'
                                : ['mp4', 'avi', 'mov', 'flv'].includes(fileType)
                                ? 'violet'
                                : ['mp3', 'm4a', 'wav'].includes(fileType)
                                ? 'teal'
                                : ['png', 'gif', 'jpg', 'jpeg'].includes(fileType)
                                ? 'yellow'
                                : 'black'
                        }
                    />
                    <Header.Content style={{ marginBottom: 0 }}>{fileType ? name : name.fileType}</Header.Content>
                    {document?.created_by && (
                        <small style={{ marginLeft: '0.2rem', opacity: 0.8 }}>
                            {t('uploaded_by')} <strong>{document?.created_by?.name}</strong> {t('at')}{' '}
                            {document?.created_on ? moment(document?.created_on).format(dateFormat + ' HH:mm') : null}
                        </small>
                    )}
                </Header>
            }
            content={<SortedDocPreview uri={file} fileType={fileType} />}
        />
    )
}

export default SortedFileRenderer
