import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-semantic-toasts';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import CanView from '@components/perms/CanView';
import Icon from '@components/Icon';
import { Form, Divider, Button, Header, Icon as SemanticIcon, Label, } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SortedFileRenderer from '@components/SortedFileRenderer';
import { 
    BtnBold,
    BtnBulletList,
    BtnClearFormatting,
    // BtnItalic,
    BtnLink,
    BtnNumberedList,
    BtnRedo,
    // BtnStrikeThrough,
    BtnUnderline,
    BtnUndo,
    HtmlButton,
    Separator,
    Toolbar,
    EditorProvider,
    Editor,
} from 'react-simple-wysiwyg';

const TaskForm = ({ onClose, view, task, setTasks }) => {
    const { t } = useTranslation()

    const [files, setFiles] = useState(task?.attachments || [])
    const [deletedFiles, setDeletedFiles] = useState([])
    const user = useSelector(state => state.user)
    const today = moment().format("YYYY-MM-DD")

    const hiddenFileInput = React.useRef(null)
    const handleFrontClick = event => {
        hiddenFileInput.current.click();
    }

    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [form, setForm] = useState({
        title: task?.title || "",
        description: task?.description || "",
        priority: task?.priority || 1,
        assigned_to: task?.assigned_to?.id || (user?.profile?.id || null),
        to_date: task?.to_date || "",
        task_date: task?.task_date || today,
        task_type: task?.task_type?.id || "",
        is_closed: task?.is_closed || false,
        attachments: task?.attachments || []
    })

    const onDelete = async (id) => {
        setFiles(files?.filter(attachment => attachment.id !== id));
        setDeletedFiles([...deletedFiles, id])
    }

    const onDeleteWithoutID = async (attachmentWithoutID) => {
        setFiles(files?.filter(attachment => attachment !== attachmentWithoutID));
    }

    const handleDelete = async (id) => {
        const request = await requests.del(API.ATTACHMENTS + id + '/')
        if (request.status === 204) {
            setTasks(prev => prev.map(item => {
                if (item.id === task.id) {
                    item.attachments = item.attachments.filter((item) => item.id !== id)
                }
                return item
            }))
        }
    }

    const handleFileInputChange = (event) => {
        const selectedFiles = event.target.files;
    
        const newFiles = Array.from(selectedFiles).filter(file => file.size <= 2 * 1024 * 1024);
    
        if (newFiles.length > 0) {
          setFiles([...files, ...newFiles]);
        } else {
            toast({
                type: 'error',
                icon: 'warning circle',
                title: t('Please select files with a size of 2 MB or less.'),
                description: "",
                animation: 'bounce',
                time: 5000,
            })
        }
    };

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors(null)

        if(deletedFiles){
            for (let i = 0; i < deletedFiles.length; i++) {
                if(deletedFiles[i]){
                    handleDelete(deletedFiles[i]);
                }
            }
            setDeletedFiles([])
        }

        let data = form
        if (form.to_date === "") data['to_date'] = null
        if (form.task_date === "") data['task_date'] = today
        data['attachments'] = {}

        if(files.filter((item) => !item.id)?.length > 0){
            const formData = new FormData()
            for (let i = 0; i < files.length; i++) {
                if(!files[i].id && files[i].size <= 2 * 1024 * 1024) formData.append("files", files[i])
            }
            
            const requestAttachments = await requests.post(API.ATTACHMENTS_BULK, formData)

            if (requestAttachments.status === 201) {
                setFiles([])
                document.getElementById('fileInput').value = null
            }
        
            data['attachments'] = { add: requestAttachments.response.map(item => item.id) }
        }
        if (task?.id === undefined) {
            const request = await requests.post(API.TASKS, data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('task_created'),
                    animation: 'pulse',
                    time: 2000,
                })

                if (form.assigned_to === user?.profile?.id || (["assigned", "all"].includes(view))) {
                    setTasks(prev => ([...prev, request.response]))
                }
                onClose()
            }
        } else {
            const request = await requests.patch(API.TASKS + task?.id + "/", data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 200) {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('task_updated'),
                    animation: 'pulse',
                    time: 2000,
                })

                if (form.assigned_to === user?.profile?.id || (["assigned", "all"].includes(view))) {
                    setTasks(prev => prev.map(item => {
                        if (item.id === request.response.id) {
                            item = request.response
                        }
                        return item
                    }))
                } else {
                    setTasks(prev => prev.filter(item => item.id !== request.response.id))
                }
            }
        }

        onClose()
        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField as="input"
                autoFocus
                required
                label={t("name")}
                error={errors?.title?.[0] || false}
                value={form.title}
                onChange={(e, { value }) => setForm(prev => ({...prev, title: value}))}
            />

            {/* <SuperField as="textarea"
                rows="10"
                label={t("description")}
                error={errors?.description?.[0] || false}
                placeholder={t('whats_on_your_mind_today__write_it_down')}
                value={form.description}
                onChange={(e, { value }) => setForm(prev => ({...prev, description: value}))}
            /> */}

            <Form.Field>
                <label style={{ fontWeight: "bold", marginBottom: "0.5rem" }}>{ t('description') }</label>
                <EditorProvider>
                    <Editor 
                        containerProps={{ style: { resize: 'vertical', width: '100%', background: "white", minHeight: "200px" } }}
                        value={form.description}
                        onChange={(e) => setForm(prev => ({...prev, description: e.target.value}))}
                    >
                        <Toolbar>
                            <BtnUndo />
                            <BtnRedo />
                            <Separator />
                            <BtnBold />
                            {/* <BtnItalic /> */}
                            <BtnUnderline />
                            {/* <BtnStrikeThrough /> */}
                            <Separator />
                            <BtnNumberedList />
                            <BtnBulletList />
                            <Separator />
                            <BtnLink />
                            <Separator />
                            <BtnClearFormatting />
                            <HtmlButton />
                        </Toolbar>
                    </Editor>
                </EditorProvider>
            </Form.Field>

            <div style={{ fontWeight: "bold", marginBottom: "0.5rem" }}>{ t('priority') }</div>
            <Button.Group basic size="small" fluid>
                <Button type="button"  active={form.priority === 4} onClick={() => setForm(prev => ({...prev, priority: 4}))} style={{ borderBottom: "2px solid var(--danger)", fontWeight: form.priority === 4 ? "bold" : "normal" }}>{t('urgent')}</Button>
                <Button type="button"  active={form.priority === 3} onClick={() => setForm(prev => ({...prev, priority: 3}))} style={{ borderBottom: "2px solid var(--warning)", fontWeight: form.priority === 3 ? "bold" : "normal" }}>{t('high')}</Button>
                <Button type="button"  active={form.priority === 2} onClick={() => setForm(prev => ({...prev, priority: 2}))} style={{ borderBottom: "2px solid var(--success)", fontWeight: form.priority === 2 ? "bold" : "normal" }}>{t('medium')}</Button>
                <Button type="button" active={form.priority === 1} onClick={() => setForm(prev => ({...prev, priority: 1}))} style={{ borderBottom: "2px solid var(--info)", fontWeight: form.priority === 1 ? "bold" : "normal" }}>{t('low')}</Button>
            </Button.Group>

            <Form.Group widths="equal" style={{ marginTop: "1rem" }}>
                <SuperField as="datepicker"
                    required
                    label={t("task_date")}
                    value={form.task_date}
                    onChange={(e, { value }) => setForm(prev => ({...prev, task_date: value}))}
                />
                <SuperField as="datepicker"
                    label={t("to_date")}
                    value={form.to_date}
                    onChange={(e, { value }) => setForm(prev => ({...prev, to_date: value}))}
                />
            </Form.Group>

            

            <Form.Group widths="equal">
                <SuperField as="choice"
                    search
                    text="title"
                    label={t('type')}
                    value={form.task_type}
                    endpoint={API.TASKS + "task_type/"}
                    onChange={(e, { value }) => setForm(prev => ({...prev, task_type: value}))}
                />
                <CanView permissions={['tasks.c_manage_tasks']}>
                    <Form.Field>
                        <SuperField as="choice"
                            search
                            text="fullname_with_titles"
                            label={t('assigned_to')}
                            value={form.assigned_to}
                            error={errors?.assigned_to?.[0] || false}
                            endpoint={API.EMPLOYEES + "?only_basic_info=true&has_user=true&is_active=true"}
                            onChange={(e, { value }) => setForm(prev => ({...prev, assigned_to: value}))}
                        />
                        { user.profile?.id && 
                            <SuperField as="checkbox"
                                style={{ marginTop: "0.5rem" }}
                                label={t('assign_me')}
                                checked={form.assigned_to === user.profile?.id}
                                onChange={() => {
                                    if (form.assigned_to !== user.profile?.id) {
                                        setForm(prev => ({
                                            ...prev,
                                            assigned_to: user.profile?.id || "",
                                        }))
                                    } else {
                                        setForm(prev => ({ ...prev, assigned_to: ""}))
                                    }
                                }}
                            />
                        }
                    </Form.Field>
                </CanView>
            </Form.Group>
            <Divider />

            <div style={{ textAlign: "center" }}>
                <input
                    type="file"
                    multiple
                    id="fileInput"
                    ref={hiddenFileInput}
                    onChange={handleFileInputChange}
                    style={{ display: "none" }}
                />
                <div 
                    style={{ 
                        padding: "0.7rem",
                        cursor: "pointer",
                        border: "1px solid var(--light-grey)",
                    }}
                    onClick={handleFrontClick}
                >
                    <Header as="h4" style={{ marginTop: "0.5rem", marginBottom: "0.5rem", fontSize: "15px" }}>
                        <span>
                            <SemanticIcon name="upload" size='tiny' style={{ color: "var(--primary)", fontSize: "15px", marginRight: '0.4rem' }} />
                            {t('upload_files')}
                        </span>
                        <Header.Subheader content={ "( " + t('2mb_limit_per_file') + " )"} />
                    </Header>
                </div>
            </div>

            { files && files.filter((item) => !item.id)?.length > 0 &&
                <div style={{ paddingTop: 0 }}>
                    { files.filter((item) => !item.id)?.map(attachment => (
                        <>
                            <Label content={attachment.name} />
                            <Icon
                                name="close"
                                style={{
                                    marginTop: "0.5rem",
                                    marginRight: "1rem",
                                    fontSize: "1.2rem",
                                    color: "var(--danger)",
                                    cursor: "pointer"
                                }}
                                onClick={() => onDeleteWithoutID(attachment)}
                            />
                        </>
                    ))}
                </div>
            }

            { files && files?.length > 0 &&
                <div style={{ paddingTop: "1rem" }}>
                    <Header as={'h5'} content={t('attached_files') + " (" + files.filter((item) => item.id).length + ")"} style={{ marginBottom: 0}} />
                    { files?.map(attachment => (
                        attachment.id &&
                        <div key={attachment.id} style={{ display: "flex", alignItems: "center" }}>
                            <Icon
                                name="close"
                                style={{
                                    marginTop: "1.6rem",
                                    marginRight: "1rem",
                                    fontSize: "1.2rem",
                                    color: "var(--danger)",
                                    cursor: "pointer"
                                }}
                                onClick={() => onDelete(attachment.id)}
                            />
                            <SortedFileRenderer name={attachment.name} file={attachment.file}/>
                        </div>
                    ))}
                </div>
            }

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    disabled={processing || form.title === "" || form.task_date === ""}
                    loading={processing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default TaskForm;