import React from 'react'
import {
    MDXEditor,
    headingsPlugin,
    listsPlugin,
    linkPlugin,
    quotePlugin,
    toolbarPlugin,
    UndoRedo,
    BlockTypeSelect,
    BoldItalicUnderlineToggles,
    ListsToggle,
    linkDialogPlugin,
    CreateLink,
    CodeToggle,
    DiffSourceToggleWrapper,
    diffSourcePlugin,
    markdownShortcutPlugin,
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import { Form } from 'semantic-ui-react'

const MKEditor = ({ readOnly, label, markdown, onChange }) => {
    return (
        <Form.Field>
            <label style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>{label}</label>
            <MDXEditor
                readOnly={readOnly || false}
                contentEditableClassName="mdxeditor"
                markdown={markdown || ''}
                onChange={onChange}
                plugins={[
                    linkPlugin(),
                    linkDialogPlugin(),
                    headingsPlugin(),
                    listsPlugin(),
                    quotePlugin(),
                    diffSourcePlugin(),
                    markdownShortcutPlugin(),
                    toolbarPlugin({
                        toolbarContents: () => (
                            <>
                                {' '}
                                <UndoRedo />
                                <BoldItalicUnderlineToggles />
                                <BlockTypeSelect />
                                <ListsToggle />
                                <CreateLink />
                                <CodeToggle />
                                <DiffSourceToggleWrapper />
                            </>
                        ),
                    }),
                ]}
            />
        </Form.Field>
    )
}

export default MKEditor
