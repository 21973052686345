import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
// store
import { isEmpty } from '@helpers/validation'
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { routes } from '@routes'
// components
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import { Form, Divider } from 'semantic-ui-react'

const DocumentForm = ({ onClose }) => {
    const { t } = useTranslation()

    const history = useHistory()

    const [isProcessing, setIsProcessing] = useState(false) // true, false

    // form formdata
    const [title, setTitle] = useState('')
    const [source, setSource] = useState('')
    const [labels, setLabels] = useState([])

    const handleSubmit = async () => {
        setIsProcessing(true)

        const request = await requests.post(API.TEMPLATING, {
            title: title,
            source: source,
            is_xml: false,
            labels: { add: labels },
            language: 'sk',
        })

        if (request.status === 201) {
            history.push(routes.SETTINGS + 'templates/' + request.response.id)
        }

        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField
                as="input"
                autoFocus
                required
                label={t('title')}
                placeholder={t('title')}
                value={title}
                onChange={(e, { value }) => setTitle(value)}
            />

            <SuperField
                as="choice"
                required
                label={t('data_source')}
                disabled={document?.id !== undefined}
                value={source}
                customOptions={[
                    { key: 1, text: t('employment_contract'), value: 'contracts.Contract' },
                    { key: 2, text: t('business_contract'), value: 'contracts.BusinessContract' },
                    { key: 3, text: t('termination'), value: 'contracts.ContractTermination' },
                    { key: 4, text: t('foreigners'), value: 'foreigners.ForeignerProcess' },
                ]}
                onChange={(e, { value }) => setSource(value)}
            />

            <SuperField
                as="choice-select"
                search
                multiple
                text="name"
                value={labels}
                label={t('tags')}
                settings="types/template-labels"
                endpoint={API.TEMPLATES + 'labels/'}
                onChange={(e, { value }) => setLabels(value)}
            />

            <Divider />
            <Form.Field style={{ textAlign: 'right', margin: ' 1rem' }}>
                <ModalCancel onClose={onClose} disabled={isProcessing} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || isEmpty(title) || isEmpty(source)}
                    text={t('save')}
                />
            </Form.Field>
        </Form>
    )
}
export default DocumentForm
