import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { icons } from '@store/icons';
import { requests } from '@helpers/requests';
// components
import { Card, Container, Segment } from 'semantic-ui-react';
import Icon from '@components/Icon';

const StatCard = ({ color, isCustomColor, number, description, icon, onClick }) => {
    return (
        <Card style={isCustomColor ? { borderRadius: 0, boxShadow: `0 0 0 1px #d4d4d5,0 2px 0 0 ${color},0 1px 3px 0 #d4d4d5` } : { borderRadius: 0 }} color={isCustomColor ? undefined : color} onClick={onClick}>
            <Card.Content>
                <div style={{ display: "flex", marginBottom: "2rem" }}>
                    <div style={{ textAlign: "left", fontWeight: "bold", width: "70%", fontSize: "1.15rem", paddingTop: "0.25rem", color: "var(--dark)" }}>
                        { number }
                    </div>
                    <div style={{ textAlign: "right", width: "30%" }}>
                        <Icon name={icon} style={{ fontSize: "2rem", fontWeight: "bold", textAlign: "left", color: color }}/>
                    </div>
                </div>

                <div style={{ position: "absolute", bottom: "1rem", marginTop: "1rem", textTransform: "uppercase", color: "var(--dark)", opacity:"0.8" }}>
                    { description }
                </div>
            </Card.Content>
        </Card>
    )
}

const OpportunityListStats = ({ data, params, fetchData, setFilters }) => {
    const { t } = useTranslation()

    const [loading, setLoading] = useState(false)
    const [stats, setStats] = useState({
        total: 0,
        qualification: 0,
        need_analysis: 0,
        value_proposition: 0,
        id_decision_makers: 0,
        preception_analysis: 0,
        proposal_price_quote: 0,
        negotitaion: 0,
        closed_won: 0,
        closed_lost: 0,
    })

    useEffect(() => {
        async function fetchStats(){
            setLoading(true)
            const request = await requests.get(API.REPORTS + "opportunities/basic_stats/?query={*}" + params)
            
            if( request.status === 200 ){
                let response = request.response
                setStats({
                    total: response?.total_opportunities || 0,
                    qualification: response?.total_qualification_opportunities || 0,
                    need_analysis: response?.total_need_analysis_opportunities || 0,
                    value_proposition: response?.total_value_proposition_opportunities || 0,
                    id_decision_makers: response?.total_id_decision_makers_opportunities || 0,
                    preception_analysis: response?.total_preception_analysis_opportunities || 0,
                    proposal_price_quote: response?.total_proposal_price_quote_opportunities || 0,
                    negotitaion: response?.total_negotitaion_opportunities || 0,
                    closed_won: response?.total_closed_won_opportunities || 0,
                    closed_lost: response?.total_closed_lost_opportunities || 0,
                })
            }
            setLoading(false)
        }

        fetchStats()
        // eslint-disable-next-line
    }, [data])

    const renderOnClick = async (stage) => {
        setFilters(prev => ({...prev, stage: stage}))
        await fetchData("&stage=" + stage, true)
    }

    return (
        <Container fluid>
            <Segment loading={loading} style={{ padding: 0, margin: 0, backgroundColor: "transparent", minHeight: "5rem", border: "none", boxShadow: "none", marginBottom: "1rem" }}>
                <Card.Group stackable doubling itemsPerRow={5}>
                    <StatCard
                        color="black"
                        number={ stats.total }
                        icon={icons.ORDERS}
                        description={t("all")}
                        onClick={() => renderOnClick("")}
                    />
                    <StatCard
                        isCustomColor
                        color="var(--info)"
                         number={ stats.qualification }
                        icon={icons.ORDERS}
                        description={t("qualification")}
                        onClick={() => renderOnClick(1)}
                    />
                    <StatCard
                        isCustomColor
                        color="var(--primary)"
                         number={ stats.need_analysis }
                        icon={icons.ORDERS}
                        description={t("needs_analysis")}
                        onClick={() => renderOnClick(2)}
                    />
                    <StatCard
                        isCustomColor
                        color="var(--warning)"
                         number={ stats.value_proposition }
                        icon={icons.ORDERS}
                        description={t("value_proposition")}
                        onClick={() => renderOnClick(3)}
                    />
                    <StatCard
                        isCustomColor
                        color="var(--blue)"
                         number={ stats.id_decision_makers }
                        icon={icons.ORDERS}
                        description={t("id_decision_makers")}
                        onClick={() => renderOnClick(4)}
                    />
                </Card.Group>
                <Card.Group stackable doubling itemsPerRow={5}>
                    <StatCard
                        isCustomColor
                        color="var(--iceblue)"
                         number={ stats.preception_analysis }
                        icon={icons.ORDERS}
                        description={t("preception_analysis")}
                        onClick={() => renderOnClick(5)}
                    />
                    <StatCard
                        isCustomColor
                        color="brown"
                         number={ stats.proposal_price_quote }
                        icon={icons.ORDERS}
                        description={t("proposal_price_quote")}
                        onClick={() => renderOnClick(6)}
                    />
                    <StatCard
                        isCustomColor
                        color="orange"
                         number={ stats.negotitaion }
                        icon={icons.ORDERS}
                        description={t("negotiation_review")}
                        onClick={() => renderOnClick(7)}
                    />
                    <StatCard
                        isCustomColor
                        color="green"
                         number={ stats.closed_won }
                        icon={icons.ORDERS}
                        description={t("closed_won")}
                        onClick={() => renderOnClick(8)}
                    />
                    <StatCard
                        isCustomColor
                        color="var(--danger)"
                         number={ stats.closed_lost }
                        icon={icons.ORDERS}
                        description={t("closed_lost")}
                        onClick={() => renderOnClick(9)}
                    />
                </Card.Group>
            </Segment>
        </Container>
    );
};

export default OpportunityListStats;