import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import '@store/languages'
// store
import { API } from '@store/config'
import { useHasPermission } from '@helpers/hooks'
import { tzDateTime, getTimezoneOffset } from '@helpers/dates'
import { requests, fileDownload } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import CanView from '@components/perms/CanView'
import SuperField from '@components/forms/SuperField'
import FlexRowWithToggle from '@components/FlexRowWithToggle'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { FlexRow, FlexItem } from '@components/tables/FlexTable'
import { Divider, Form, Button, Segment, Popup, Dropdown } from 'semantic-ui-react'
// specific components
import WeekManagement from '../components/management/WeekManagement'
import AssignAttendanceBonusForm from '../components/management/AssignAttendanceBonusForm'
// eslint-disable-next-line
import LongtermAbsenceForm from '../components/management/LongtermAbsenceForm'

const ManageEmployee = () => {
    const { t } = useTranslation()
    const date_format = useSelector((state) => state.date_format)
    const profile = useSelector((state) => state.user.profile)
    const canManageOnlyAssignedEmployees = useHasPermission(
        'attendance.c_can_manage_only_employees_as_responsible_person'
    )

    // eslint-disable-next-line
    const excludeSelf = useHasPermission('attendance.c_can_not_manage_self_attendance')

    const [month, setMonth] = useState(moment().format('MM'))
    const [year, setYear] = useState(moment().format('YYYY'))
    const [employee, setEmployee] = useState('')
    const [unit, setUnit] = useState('')
    const [contract, setContract] = useState(null)
    const [absenceTypes, setAbsenceTypes] = useState([])
    const [loading, setLoading] = useState(false)
    const [isGenerated, setIsGenerated] = useState(false)
    const [excludeWeekend, setExcludeWeekend] = useState(false)
    const [showCorrectedRecords, setShowCorrectedRecords] = useState(false)
    const [loadingShifts, setLoadingShifts] = useState(false)

    const [holidays, setHolidays] = useState({})
    const [shifts, setShifts] = useState([])
    const [weeks, setWeeks] = useState([])
    const [records, setRecords] = useState([])
    const [weeksStats, setWeeksStats] = useState([])
    const [canPerformCorrections, setCanPerformCorrections] = useState(false)
    const [viewVoucher, setViewVoucher] = useState(false)
    const [viewSecondBreak, setViewSecondBreak] = useState(false)
    const [interruptionReasons, setInterruptionReasons] = useState([])

    const fetchAbsenceTypes = async () => {
        const request = await requests.get(API.ATTENDANCE_BASE + 'absence_types/?is_active=true')
        if (request.status === 200) setAbsenceTypes(request.response)
    }

    const fetchShifts = async () => {
        setLoadingShifts(true)
        const request = await requests.get(API.ATTENDANCE_BASE + 'shifts/')

        if (request.status === 200) {
            setShifts(
                request.response.map((item) => ({ ...handleShiftConvertion(item), is_processing: false, errors: null }))
            )
        }
        setLoadingShifts(false)
    }

    const fetchInterruptionReasons = async () => {
        const request = await requests.get(API.ATTENDANCE_INTERRUPTION_REASONS + '?query={id, title}')
        if (request.status === 200) setInterruptionReasons(request.response)
    }

    const fetchPreferences = async () => {
        const request = await requests.get(
            API.PREFERENCES + 'attendance_preferences__allow_attendance_record_correction/'
        )
        if (request.status === 200) {
            setCanPerformCorrections(request.response.value)
        }

        const requestVoucherPref = await requests.get(
            API.PREFERENCES + 'attendance_preferences__global_lunch_voucher_preferences/'
        )
        if (requestVoucherPref.status === 200) {
            setViewVoucher(parseInt(requestVoucherPref?.response?.value || 0) > 0)
        }

        const requestSecondBreakPref = await requests.get(
            API.PREFERENCES + 'attendance_preferences__global_second_break_requirement/'
        )
        if (requestSecondBreakPref.status === 200) {
            setViewSecondBreak(parseFloat(requestSecondBreakPref?.response?.value || 0) > 0)
        }
    }

    useEffect(() => {
        fetchAbsenceTypes()
        fetchPreferences()
        fetchShifts()
        // eslint-disable-next-line
    }, [])

    const handleShiftConvertion = (shift) => {
        if (!['', null].includes(shift.time_from)) {
            let datetime_from = moment(moment().format('YYYY-MM-DD') + ' ' + shift.time_from).format('YYYY-MM-DD HH:mm')
            // convert to Localtime:
            shift.time_from = tzDateTime(datetime_from).format('HH:mm')
        }

        if (!['', null].includes(shift.time_to)) {
            let datetime_to = moment(moment().format('YYYY-MM-DD') + ' ' + shift.time_to).format('YYYY-MM-DD HH:mm')
            // convert to Localtime:
            shift.time_to = tzDateTime(datetime_to).format('HH:mm')
        }

        if (!['', null].includes(shift.pause_length)) {
            if (parseFloat(shift.pause_length) > 0) {
                shift.pause_length = parseFloat(shift.pause_length)
            }
        }
        return shift
    }

    const monthList = [
        { key: 1, value: '01', text: t('january') },
        { key: 2, value: '02', text: t('february') },
        { key: 3, value: '03', text: t('march') },
        { key: 4, value: '04', text: t('april') },
        { key: 5, value: '05', text: t('may') },
        { key: 6, value: '06', text: t('june') },
        { key: 7, value: '07', text: t('july') },
        { key: 8, value: '08', text: t('august') },
        { key: 9, value: '09', text: t('september') },
        { key: 10, value: '10', text: t('october') },
        { key: 11, value: '11', text: t('november') },
        { key: 12, value: '12', text: t('december') },
    ]

    const getDates = (from, to) => {
        let dates = []
        // add logic to calculate days between two date range
        for (let day = from; day.isSameOrBefore(to); day.add(1, 'days')) {
            dates.push(day.format('YYYY-MM-DD'))
        }

        return dates
    }

    const generateXLSXReport = async (id) => {
        let correctedRecordsQuery = '&ignore_attendance_correction_preference=true'
        if (showCorrectedRecords) correctedRecordsQuery = ''
        const firstDay = moment(year + '-' + month + '-01')
            .startOf('month')
            .format('YYYY-MM-DD')
        const lastDay = moment(year + '-' + month + '-01')
            .endOf('month')
            .format('YYYY-MM-DD')
        await fileDownload(
            'GET',
            API.API_URL +
                `/exports/simplified_dynamic_attendance/?is_blob=true&contracts=${employee}&date_from=${firstDay}&date_to=${lastDay}&timezone=${getTimezoneOffset(
                    firstDay + ' 11:00'
                )}${correctedRecordsQuery}`,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            `${t('monthly_attendance')} - ${contract?.employee?.fullname_with_titles || ''} - ${
                month + ' - ' + year
            }.xlsx`
        )
    }

    const generateXLSXAbsenceReport = async (id) => {
        const firstDay = moment(year + '-' + month + '-01')
            .startOf('form.')
            .format('YYYY-MM-DD')
        const lastDay = moment(year + '-' + month + '-01')
            .endOf('month')
            .format('YYYY-MM-DD')

        let queryParam = ''
        if (employee !== '') queryParam += `&contracts=${employee}`

        await fileDownload(
            'GET',
            API.EXPORTS + `payrolls/absences/excel/?date_from=${firstDay}&date_to=${lastDay}${queryParam}`,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            `${t('absences_report')} - ${contract?.employee?.fullname_with_titles || ''} - ${month + ' - ' + year}.xlsx`
        )
    }

    const generatePDFReport = async () => {
        let correctedRecordsQuery = '&ignore_attendance_correction_preference=true'
        if (showCorrectedRecords) correctedRecordsQuery = ''
        const firstDay = moment(year + '-' + month + '-01')
            .startOf('month')
            .format('YYYY-MM-DD')
        const lastDay = moment(year + '-' + month + '-01')
            .endOf('month')
            .format('YYYY-MM-DD')

        await fileDownload(
            'GET',
            API.API_URL +
                `/exports/attendance_pdf_download/${employee}/?is_blob=true&date_from=${firstDay}&date_to=${lastDay}&timezone=${getTimezoneOffset(
                    firstDay + ' 10:00'
                )}${correctedRecordsQuery}`,
            'application/pdf',
            `${t('monthly_attendance')} - ${contract?.employee?.fullname_with_titles || ''} - ${
                month + ' - ' + year
            }.pdf`
        )
    }

    const generateMonthlyAttendance = async () => {
        setLoading(true)
        const firstDay = moment(year + '-' + month + '-01').startOf('month')
        const lastDay = moment(year + '-' + month + '-01').endOf('month')
        const dates = getDates(firstDay, lastDay)
        const duplicate_weeks = dates.map((date) => moment(date).isoWeek())
        const unique_weeks = [...new Set(duplicate_weeks)]
        setWeeks(unique_weeks)
        const attendance = []

        const request = await requests.get(API.COMMON + 'holidays/?country=SK&year=' + year)
        if (request.status === 200) setHolidays(request.response)

        const requestContract = await requests.get(API.CONTRACTS + employee + '/?only_basic_info=true')
        if (requestContract.status === 200) {
            setContract(requestContract.response)
        }

        await fetchInterruptionReasons()

        for (let i = 0; i < dates.length; i++) {
            attendance.push({
                week: moment(dates[i]).isoWeek(),
                day: dates[i],
            })
        }

        const attendance_week_stats = []
        for (let i = 0; i < unique_weeks.length; i++) {
            // calculate week start and end date
            let correctedRecordsQuery = '&ignore_attendance_correction_preference=true'
            if (showCorrectedRecords) correctedRecordsQuery = ''
            const weekDates = attendance.filter((item) => item.week === unique_weeks[i])
            const first_day = weekDates[0]?.day
            const last_day = weekDates[weekDates.length - 1]?.day
            const request_week_stats = await requests.get(
                API.ATTENDANCE +
                    `employee_stats/?date_from=${first_day}&date_to=${last_day}&contracts=${employee}${correctedRecordsQuery}`
            )

            if (request_week_stats.status === 200) {
                // set response from the API call
                attendance_week_stats.push({
                    week: unique_weeks[i],
                    ...request_week_stats.response,
                })
            } else {
                // set default on API call error
                attendance_week_stats.push({
                    week: unique_weeks[i],
                    working_hours: 0,
                    lunch_voucher: 0,
                    // vacation: 0,
                    // vacation_hours: 0,
                    // absention: 0,
                    // paragraph: 0,
                    // paragraph_family_member: 0,
                    // sick_day: 0,
                    // care_for_a_family_member: 0,
                    absences: [],
                    require_manual_corrections: 0,
                    duplicated_records: [],
                })
            }
        }

        setWeeksStats(attendance_week_stats)
        setRecords(attendance)
        setTimeout(() => {
            setIsGenerated(true)
            setLoading(false)
        }, 500)
    }

    const getWeekDays = (week) => {
        return records.filter((record) => record.week === week).filter((item) => !isWeekendDay(item.day))
    }

    const isWeekendDay = (day) => {
        if (excludeWeekend === false) return false

        return [0, 6].includes(moment(day).day())
    }

    const countStat = (attribute) => {
        let total = 0
        for (let i = 0; i < weeksStats.length; i++) {
            if (attribute === 'absences') {
                let absences = weeksStats[i][attribute]
                for (let y = 0; y < absences.length; y++) {
                    if (absences[y].source !== 'vacation') {
                        const value = absences[y].value
                        total +=
                            isNaN(value) || value === undefined || value === null || value === ''
                                ? 0
                                : parseFloat(value)
                    }
                }
            } else if (attribute === 'vacation') {
                let absences = weeksStats[i]['absences']
                for (let y = 0; y < absences.length; y++) {
                    if (absences[y].source === 'vacation') {
                        const value = absences[y].value
                        total +=
                            isNaN(value) || value === undefined || value === null || value === ''
                                ? 0
                                : parseFloat(value)
                    }
                }
            } else {
                const value = weeksStats[i][attribute]
                total += isNaN(value) || value === undefined || value === null || value === '' ? 0 : parseFloat(value)
            }
        }

        return attribute === 'working_hours' ? parseFloat(total).toFixed(2) : total
    }

    const countWeekStat = (attribute, week) => {
        let total = 0
        const current_week_stats = weeksStats.find((item) => item.week === week)
        if (current_week_stats !== undefined) {
            if (attribute === 'absences') {
                let absences = current_week_stats?.[attribute]
                for (let y = 0; y < absences.length; y++) {
                    if (absences[y].source !== 'vacation') {
                        const value = absences[y].value
                        total +=
                            isNaN(value) || value === undefined || value === null || value === ''
                                ? 0
                                : parseFloat(value)
                    }
                }
            } else if (attribute === 'vacation') {
                let absences = current_week_stats?.['absences']
                for (let y = 0; y < absences.length; y++) {
                    if (absences[y].source === 'vacation') {
                        const value = absences[y].value
                        total +=
                            isNaN(value) || value === undefined || value === null || value === ''
                                ? 0
                                : parseFloat(value)
                    }
                }
            } else {
                const value = current_week_stats?.[attribute] || 0
                total = isNaN(value) || value === undefined || value === null || value === '' ? 0 : parseFloat(value)
            }
            return attribute === 'working_hours' ? parseFloat(total).toFixed(2) : total
        }

        return total
    }

    const findDuplicatedRecords = (week) => {
        let total = 0
        const current_week_stats = weeksStats.find((item) => item.week === week)
        if (current_week_stats !== undefined) {
            return current_week_stats?.['duplicated_records'] || []
        }

        return total
    }

    const getContractAdditionalFilters = () => {
        const query =
            '{id, fond, fond_per_unit, fond_per_unit_display, employee, employer, work_position, available_vacation_fond, bank_hours, attendance_lock, payroll}'
        let baseQueryParams = `&query=${query}&only_basic_info=true`
        baseQueryParams += canManageOnlyAssignedEmployees && profile?.id ? '&responsible_persons=' + profile.id : ''
        baseQueryParams += unit ? '&unit=' + unit : ''

        const firstDay = moment(year + '-' + month + '-01')
            .startOf('month')
            .format('YYYY-MM-DD')
        const lastDay = moment(year + '-' + month + '-01')
            .endOf('month')
            .format('YYYY-MM-DD')

        // get me current day and first day in month from previous month
        let currentDay = moment()
        if (currentDay.isAfter(moment(lastDay))) {
            currentDay = moment(lastDay)
        }

        const previousMonth = currentDay.clone().subtract(1, 'months')
        const contract_date_to = currentDay.format('YYYY-MM-DD')
        const contract_date_from = previousMonth.clone().startOf('month').format('YYYY-MM-DD')

        baseQueryParams += `&valid_contract_based_on_date_from=${contract_date_from}&valid_contract_based_on_date_to=${contract_date_to}`
        baseQueryParams += `&payroll_date_from=${firstDay}&payroll_date_to=${lastDay}`
        baseQueryParams += `&lock_date_from=${firstDay}&lock_date_to=${lastDay}`

        if (profile && excludeSelf) {
            baseQueryParams += `&exclude_profiles=${profile.id}`
        }

        return baseQueryParams
    }

    return (
        <CanView permissions={['attendance.c_manage_all_records']} redirect>
            <Form>
                <Form.Group widths={6}>
                    <SuperField
                        as="yearpicker"
                        value={year}
                        width="2"
                        label={t('year')}
                        style={{ position: 'relative', top: '-0.1rem' }}
                        dateFormat="YYYY"
                        onChange={(e, { value }) => {
                            setYear(value)
                            setEmployee('')
                        }}
                    />
                    <SuperField
                        as="choice"
                        search
                        width="2"
                        label={t('month')}
                        value={month}
                        clearable={false}
                        customOptions={monthList}
                        onChange={(e, { value }) => {
                            setMonth(value)
                            setEmployee('')
                        }}
                    />
                    <SuperField
                        as="choice-select"
                        search
                        value={unit}
                        label={t('department')}
                        onChange={(e, { value }) => {
                            setUnit(value)
                            setEmployee('')
                        }}
                        endpoint={API.UNITS}
                        text="name"
                        additionalFilters={
                            '&query={id, name}&only_basic_info=true&only_from_active_company=true' +
                            (canManageOnlyAssignedEmployees && profile?.id ? '&responsible_persons=' + profile.id : '')
                        }
                        help={t('select_a_department_to_retrieve_a_list_of_employees_in_that_department')}
                    />

                    <SuperField
                        as="choice-select"
                        search
                        required
                        label={t('employee')}
                        key={getContractAdditionalFilters()} // when string changes this because unique and rerender itself (which is required to load new options)
                        value={employee}
                        text={(item) =>
                            item?.employee?.fullname_with_titles +
                            ' - ' +
                            (item.employer?.name || '') +
                            (item.work_position ? ' (' + item.work_position.title + ')' : '')
                        }
                        endpoint={API.CONTRACTS}
                        additionalFilters={getContractAdditionalFilters()}
                        onChange={(e, { value }) => {
                            if (value === '') setIsGenerated(false)
                            setEmployee(value)
                        }}
                    />

                    <Form.Field style={{ marginTop: '1.8rem' }}>
                        <Button
                            primary
                            type="button"
                            onClick={() => generateMonthlyAttendance()}
                            disabled={employee === '' || month === '' || year === ''}
                        >
                            {t('confirm')}
                        </Button>
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <SuperField
                        as="checkbox"
                        label={t('exclude_weekend')}
                        checked={excludeWeekend}
                        onChange={() => setExcludeWeekend(!excludeWeekend)}
                    />
                    <CanView permissions={['attendance.c_can_create_corrections']}>
                        {canPerformCorrections && (
                            <SuperField
                                as="checkbox"
                                label={t('show_corrected_records')}
                                checked={showCorrectedRecords}
                                onChange={() => setShowCorrectedRecords(!showCorrectedRecords)}
                            />
                        )}
                    </CanView>
                </Form.Group>
            </Form>
            <Divider />
            <Segment
                loading={loading}
                style={{
                    padding: 0,
                    background: 'transparent',
                    boxShadow: 'none',
                    border: 'none',
                    marginBottom: '1rem',
                    marginTop: '1rem',
                }}
            >
                {loading && (
                    <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '6rem' }}>
                        {t('loading_monthly_attendance')}
                    </p>
                )}

                {!isGenerated && !loading && (
                    <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '1rem', fontWeight: 'bold' }}>
                        {t('select_employee_to_generate_monthly_attendance')}
                    </p>
                )}

                {!loading && isGenerated && (
                    <Form>
                        <FlexRow>
                            <FlexItem fontSize="0.6rem">
                                <small>
                                    <strong style={{ fontSize: '1.2rem' }}>
                                        {' '}
                                        {monthList.find((item) => item.value === month)?.text || ''} {year}{' '}
                                    </strong>{' '}
                                    <br />
                                    <Icon name="calendar-outline" style={{ marginRight: '0.5rem' }} />
                                    <span style={{ position: 'relative', top: '-0.1rem' }}>
                                        {moment(moment(year + '-' + month + '-01').startOf('month')).format(
                                            date_format
                                        )}
                                    </span>
                                    <Icon
                                        name="calendar-outline"
                                        style={{ marginLeft: '1rem', marginRight: '0.5rem' }}
                                    />
                                    <span style={{ position: 'relative', top: '-0.1rem' }}>
                                        {moment(moment(year + '-' + month + '-01').endOf('month')).format(date_format)}
                                    </span>
                                </small>
                            </FlexItem>
                            <FlexItem basis="30%" textAlign="center">
                                <small>
                                    <strong>{t('total_days')}</strong> <br />
                                    <span> {records.filter((item) => !isWeekendDay(item.day)).length} </span>
                                </small>
                            </FlexItem>
                            <FlexItem basis="50%" textAlign="center">
                                <small>
                                    <strong>{t('working_hours')}</strong> <br />
                                    <span>
                                        {' '}
                                        {countStat('working_hours')} {t('hours_shortcut').toLocaleLowerCase()}.
                                    </span>
                                </small>
                            </FlexItem>
                            <FlexItem basis="40%" textAlign="center">
                                <small>
                                    <strong>{t('vacation')}</strong> <br />
                                    <span>
                                        {' '}
                                        {countStat('vacation')} {t('hours_shortcut').toLocaleLowerCase()}.
                                    </span>
                                </small>
                            </FlexItem>
                            <FlexItem basis="50%" textAlign="center">
                                <small>
                                    <strong>{t('work_absence')}</strong> <br />
                                    <span>
                                        {' '}
                                        {countStat('absences')} {t('hours_shortcut').toLocaleLowerCase()}.
                                    </span>
                                </small>
                            </FlexItem>
                            <FlexItem basis="40%" textAlign="center">
                                <small>
                                    <strong>{t('total')}</strong> <br />
                                    <span>
                                        {' '}
                                        {parseFloat(
                                            parseFloat(countStat('working_hours') || 0) +
                                                parseFloat(countStat('absences') || 0) +
                                                parseFloat(countStat('vacation'))
                                        ).toFixed(2)}{' '}
                                        {t('hours_shortcut').toLocaleLowerCase()}.
                                    </span>
                                </small>
                            </FlexItem>
                            <FlexItem basis="50%" textAlign="center">
                                <small>
                                    <strong>{t('vacation_status')}</strong> <br />
                                    <span>
                                        {contract?.available_vacation_fond || 0} {t('days_left').toLocaleLowerCase()}
                                    </span>
                                </small>
                            </FlexItem>
                            {canPerformCorrections && (
                                <FlexItem basis="50%" textAlign="center">
                                    <small>
                                        <strong>
                                            {t('bank_hours')}{' '}
                                            <Popup
                                                position="top center"
                                                trigger={
                                                    <sup>
                                                        <Icon
                                                            style={{ fontSize: '0.9rem' }}
                                                            name="information-circle-outline"
                                                        />
                                                    </sup>
                                                }
                                                content={t('bank_hours_hint')}
                                            />
                                        </strong>{' '}
                                        <br />
                                        <span>
                                            {contract?.bank_hours || 0} {t('hours_shortcut').toLocaleLowerCase()}.
                                        </span>
                                    </small>
                                </FlexItem>
                            )}
                            {viewVoucher && (
                                <FlexItem basis="30%" textAlign="center">
                                    <small>
                                        <strong>{t('lunch_voucher')}</strong> <br />
                                        <span> {countStat('lunch_voucher')} </span>
                                    </small>
                                </FlexItem>
                            )}

                            <FlexItem basis="40%" textAlign="right">
                                <Dropdown direction="left" primary simple as={Button} text={t('action')}>
                                    <Dropdown.Menu style={{ borderRadius: '0' }}>
                                        <CanView
                                            permissions={[
                                                'payrolls.c_manage_all_bonus_payments',
                                                'payrolls.c_manage_user_bonus_payments',
                                            ]}
                                        >
                                            <SuperDuperModal
                                                header={t('assign_bonus')}
                                                trigger={<Dropdown.Item>{t('assign_bonus')}</Dropdown.Item>}
                                                content={
                                                    <AssignAttendanceBonusForm
                                                        monthList={monthList}
                                                        contract={contract}
                                                        month={month}
                                                        year={year}
                                                    />
                                                }
                                            />
                                        </CanView>
                                        {/* <SuperDuperModal
                                            header={t('longterm_work_absence')}
                                            size="tiny"
                                            trigger={<Dropdown.Item>{t('add_longterm_work_absence')}</Dropdown.Item>}
                                            content={
                                                <LongtermAbsenceForm
                                                    absenceTypes={absenceTypes}
                                                    employee={contract?.employee?.id}
                                                    records={weeksStats}
                                                    setRecords={setRecords}
                                                />
                                            }
                                        /> */}
                                        <CanView
                                            permissions={[
                                                'attendance.c_view_all_records',
                                                'attendance.c_can_manage_only_employees_as_responsible_person',
                                            ]}
                                        >
                                            <Dropdown.Item onClick={() => generateXLSXReport()}>
                                                {t('download_monthly_attendance_as_xlsx')}
                                            </Dropdown.Item>
                                        </CanView>
                                        <CanView
                                            permissions={[
                                                'attendance.c_view_all_records',
                                                'attendance.c_can_manage_only_employees_as_responsible_person',
                                            ]}
                                        >
                                            <Dropdown.Item onClick={() => generatePDFReport()}>
                                                {t('download_monthly_attendance_as_pdf')}
                                            </Dropdown.Item>
                                        </CanView>
                                        <CanView
                                            permissions={[
                                                'attendance.c_view_all_records',
                                                'attendance.c_can_manage_only_employees_as_responsible_person',
                                            ]}
                                        >
                                            <Dropdown.Item onClick={() => generateXLSXAbsenceReport()}>
                                                {t('download_monthly_absences_as_xlsx')}
                                            </Dropdown.Item>
                                        </CanView>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </FlexItem>
                        </FlexRow>
                        <Divider />
                        {weeks.map((week, idx) => (
                            <FlexRowWithToggle
                                background={'var(--white)'}
                                key={idx}
                                firstItemBasis={'2%'}
                                loading={false}
                                isOpen={moment().isoWeek() === week}
                                rowContent={
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            gap: '7rem',
                                            width: '100%',
                                        }}
                                    >
                                        <div style={{ textAlign: 'left', marginRight: '3rem' }}>
                                            <strong>
                                                {t('week')} {week}:{' '}
                                            </strong>
                                            <Icon
                                                name="calendar-outline"
                                                style={{ marginLeft: '1rem', marginRight: '0.5rem' }}
                                            />
                                            <span style={{ position: 'relative', top: '-0.1rem' }}>
                                                {moment(getWeekDays(week)?.[0]?.day).format(date_format)}
                                            </span>
                                            <Icon
                                                name="calendar-outline"
                                                style={{ marginLeft: '1rem', marginRight: '0.5rem' }}
                                            />
                                            <span style={{ position: 'relative', top: '-0.1rem' }}>
                                                {moment(getWeekDays(week)?.[getWeekDays(week).length - 1]?.day).format(
                                                    date_format
                                                )}
                                            </span>
                                            {(countWeekStat('require_manual_corrections', week) > 0 ||
                                                findDuplicatedRecords(week).length > 0) && (
                                                <Popup
                                                    position="right center"
                                                    trigger={
                                                        <Icon
                                                            name="alert-circle"
                                                            style={{
                                                                color: 'var(--danger)',
                                                                fontSize: '1.5rem',
                                                                marginLeft: '1rem',
                                                                position: 'relative',
                                                                top: '0.2rem',
                                                            }}
                                                        />
                                                    }
                                                    content={t('week_contains_records_that_require_manual_correction')}
                                                />
                                            )}
                                        </div>

                                        <div style={{ textAlign: 'center' }}>
                                            <small>
                                                <strong>{t('total_days')}</strong> <br />
                                                <span>
                                                    {' '}
                                                    {
                                                        records
                                                            .filter((record) => record.week === week)
                                                            .filter((item) => !isWeekendDay(item.day)).length
                                                    }{' '}
                                                </span>
                                            </small>
                                        </div>
                                        <div style={{ textAlign: 'center' }}>
                                            <small>
                                                <strong>{t('working_hours')}</strong> <br />
                                                <span>
                                                    {' '}
                                                    {countWeekStat('working_hours', week)}{' '}
                                                    {t('hours_shortcut').toLocaleLowerCase()}.{' '}
                                                </span>
                                            </small>
                                        </div>
                                        <div style={{ textAlign: 'center' }}>
                                            <small>
                                                <strong>{t('vacation')}</strong> <br />
                                                <span>
                                                    {' '}
                                                    {countWeekStat('vacation', week)}{' '}
                                                    {t('hours_shortcut').toLocaleLowerCase()}.{' '}
                                                </span>
                                            </small>
                                        </div>
                                        <div style={{ textAlign: 'center' }}>
                                            <small>
                                                <strong>{t('work_absence')}</strong> <br />
                                                <span>
                                                    {' '}
                                                    {countWeekStat('absences', week)}{' '}
                                                    {t('hours_shortcut').toLocaleLowerCase()}.
                                                </span>
                                            </small>
                                        </div>
                                        <div style={{ textAlign: 'center' }}>
                                            <small>
                                                <strong>{t('total')}</strong> <br />
                                                <span>
                                                    {' '}
                                                    {parseFloat(
                                                        parseFloat(countWeekStat('working_hours', week) || 0) +
                                                            parseFloat(countWeekStat('vacation', week) || 0) +
                                                            parseFloat(countWeekStat('absences', week) || 0)
                                                    ).toFixed(2)}{' '}
                                                    {t('hours_shortcut').toLocaleLowerCase()}.
                                                </span>
                                            </small>
                                        </div>
                                        {viewVoucher && (
                                            <div style={{ textAlign: 'center' }}>
                                                <small>
                                                    <strong>{t('lunch_voucher')}</strong> <br />
                                                    <span> {countWeekStat('lunch_voucher', week)}</span>
                                                </small>
                                            </div>
                                        )}
                                    </div>
                                }
                                content={
                                    <WeekManagement
                                        week={week}
                                        weekDates={records.filter((item) => item.week === week)}
                                        employee={employee}
                                        contract={contract}
                                        setContract={setContract}
                                        excludeWeekend={excludeWeekend}
                                        absenceTypes={absenceTypes}
                                        viewVoucher={viewVoucher}
                                        viewSecondBreak={viewSecondBreak}
                                        setWeeksStats={setWeeksStats}
                                        loadingShifts={loadingShifts}
                                        interruptionReasons={interruptionReasons}
                                        showCorrectedRecords={showCorrectedRecords}
                                        holidays={holidays}
                                        shifts={shifts}
                                    />
                                }
                            />
                        ))}
                    </Form>
                )}
            </Segment>
        </CanView>
    )
}

export default ManageEmployee
