import React from 'react'
import { useTranslation } from 'react-i18next'
// store
import { renderAddress } from '@helpers/functions'
// components
import { Form } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'

const Address = ({ isForm, address, setAddress, slashPushFromTop }) => {
    const { t } = useTranslation()

    slashPushFromTop = slashPushFromTop || '0'

    return (
        <>
            {!isForm ? (
                renderAddress(address)
            ) : (
                <>
                    <Form.Group>
                        <SuperField
                            as="input"
                            width="7"
                            placeholder={t('address_street')}
                            value={address.street}
                            onChange={(e, { value }) =>
                                setAddress((prev) => ({
                                    ...prev,
                                    street: value,
                                }))
                            }
                        />
                        <SuperField
                            as="input"
                            width="4"
                            placeholder={t('address_number')}
                            value={address.number}
                            onChange={(e, { value }) =>
                                setAddress((prev) => ({
                                    ...prev,
                                    number: value,
                                }))
                            }
                        />
                        <Form.Field width="1" style={{ textAlign: 'center', fontSize: '2rem' }}>
                            <span style={{ position: 'relative', top: slashPushFromTop }}>/</span>
                        </Form.Field>
                        <SuperField
                            as="input"
                            width="4"
                            placeholder={t('orientation_number')}
                            value={address.orientation_number}
                            onChange={(e, { value }) =>
                                setAddress((prev) => ({
                                    ...prev,
                                    orientation_number: value,
                                }))
                            }
                        />
                    </Form.Group>
                    <Form.Group>
                        <SuperField
                            as="input"
                            width="6"
                            placeholder={t('postcode')}
                            value={address.postcode}
                            onChange={(e, { value }) =>
                                setAddress((prev) => ({
                                    ...prev,
                                    postcode: value,
                                }))
                            }
                        />
                        <SuperField
                            as="input"
                            width="10"
                            placeholder={t('city')}
                            value={address.city}
                            onChange={(e, { value }) =>
                                setAddress((prev) => ({
                                    ...prev,
                                    city: value,
                                }))
                            }
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField
                            as="input"
                            value={address.state}
                            placeholder={t('address_state')}
                            onChange={(e, { value }) =>
                                setAddress((prev) => ({
                                    ...prev,
                                    state: value,
                                }))
                            }
                        />
                        <SuperField
                            style={{ marginTop: "0rem" }}
                            as="choice"
                            search
                            type="countries"
                            value={address.country}
                            onChange={(e, { value }) =>
                                setAddress((prev) => ({
                                    ...prev,
                                    country: value,
                                }))
                            }
                        />
                    </Form.Group>
                    <SuperField
                        as="input"
                        placeholder={t('additional_information')}
                        value={address.address_line}
                        onChange={(e, { value }) =>
                            setAddress((prev) => ({
                                ...prev,
                                address_line: value,
                            }))
                        }
                    />
                </>
            )}
        </>
    )
}

export default Address
