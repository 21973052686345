import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { requests } from '@helpers/requests'
import { API } from '@store/config'
// components
import { Divider, Form } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const PromiseBulk = ({ selected, setSelected, setData, onClose }) => {
    const { t } = useTranslation()
    
    const [isProcessing, setIsProcessing] = useState(false)
    const [form, setForm] = useState({
        promise_from: null,
        promise_to: null,
        promise_valid_until: null,
        employer: '',
        user_employer: '',
        position: '',
        id_vpm: '',
        salary: null,
        fond: null,
        fond_per_unit: null,
    })

    const onSubmit = async () => {
        setIsProcessing(true)
        let updated = []

        for(let i = 0; i < selected.length; i++){
            const request = await requests.patch(API.FOREIGNERS + "processes/" + selected[i].id + "/", {
                ...form,
                promise_from: form.promise_from ? form.promise_from : null,
                promise_to: form.promise_to ? form.promise_to : null,
                promise_valid_until: form.promise_valid_until ? form.promise_valid_until : null,
                employer: form.employer ? form.employer : null,
                user_employer: form.user_employer ? form.user_employer : null,
                salary: form.salary ? form.salary : null,
                fond: form.fond ? form.fond : null,
                fond_per_unit: form.fond_per_unit ? form.fond_per_unit : null,
            })
            if (request.status === 200) {
                updated.push(request.response) 
            }
        }

        setData(prev => prev.map(item => {
            const itemToUpdate = updated.find(updatedItem => updatedItem.id === item.id)
            if (item.id === itemToUpdate?.id) {
                item = itemToUpdate
            }
            return item
        }))

        onClose()
        setSelected([])
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group widths="equal">
                <SuperField as="choice-select"
                    search
                    text="name"
                    label={t('employer')}
                    value={form?.employer}
                    endpoint={API.BUSINESS_DETAIL}
                    additionalFilters={"&is_employer=true&query={id, name}"}
                    onChange={(e, { value }) => setForm(prev => ({ ...prev, employer: value }))}
                />
                <SuperField as="choice-select"
                    search
                    text="name"
                    label={t('user_employer')}
                    value={form?.user_employer}
                    endpoint={API.BUSINESS_DETAIL}
                    additionalFilters={"&is_employer=true&query={id, name}"}
                    onChange={(e, { value }) => setForm(prev => ({ ...prev, user_employer: value }))}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField
                    as="choice-select"
                    search
                    text="title"
                    label={t('position')}
                    value={form.position}
                    endpoint={API.JOB_POSITIONS}
                    additionalFilters={"&query={id, title}&only_basic_info=true"}
                    onChange={(e, { value }) => setForm(prev => ({ ...prev, position: value }))}
                />
            </Form.Group>
            <SuperField
                as='input'
                label={t('id_vpm')}
                value={form.id_vpm}
                onChange={(e, { value }) => setForm(prev => ({ ...prev, id_vpm: value }))}
            />

            <Form.Group widths="equal">
                <SuperField
                    as='input'
                    label={t('salary')}
                    value={form.salary}
                    onChange={(e, { value }) => setForm(prev => ({ ...prev, salary: value }))}
                />
                <SuperField
                    as='input'
                    label={t('fond')}
                    value={form.fond}
                    onChange={(e, { value }) => setForm(prev => ({ ...prev, fond: value }))}
                />
                <SuperField
                    as="choice-select"
                    type="fond_per_type"
                    label={t('fond_per_unit')}
                    value={form.fond_per_unit}
                    onChange={(e, { value }) => setForm(prev => ({ ...prev, fond_per_unit: value }))}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <SuperField
                    as="datepicker"
                    label={t('employment_from')}
                    value={form.promise_from}
                    onChange={(e, { value }) => { setForm(prev => ({ ...prev, promise_from: value })) }}
                />
                <SuperField
                    as="datepicker"
                    label={t('employment_to')}
                    value={form.promise_to}
                    onChange={(e, { value }) => { setForm(prev => ({ ...prev, promise_to: value })) }}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <SuperField
                    as="datepicker"
                    label={t('document_valid_until')}
                    value={form.promise_valid_until}
                    onChange={(e, { value }) => { setForm(prev => ({ ...prev, promise_valid_until: value })) }}
                />
            </Form.Group>

            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    disabled={isProcessing}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    )
}

export default PromiseBulk