import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'
// store
import { API } from '@store/config'
import { icons } from '@store/icons'
import { routes } from '@routes'
// components
import ListView from '@components/ListView'
import AvatarList from '@components/AvatarList'
import CanView from '@components/perms/CanView'
import DatePresets from '@components/DatePresets'
import SuperField from '@components/forms/SuperField'
import BreadcrumbNav from '@components/BreadcrumbNav'
import { Form, Divider, Label } from 'semantic-ui-react'
// module specific
import ProjectForm from '../components/forms/ProjectForm'

const ProjectList = () => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)

    return (
        <CanView permissions={['projects.c_view_all_projects', 'projects.c_view_user_projects']} redirect>
            <BreadcrumbNav items={[{ name: t('projects'), icon: icons.PROJECTS, href: '' }]} />
            <ListView
                as="table"
                allowSearch
                isSoftDeleteAllowed
                actionsCellWidth="2"
                endpoint={API.PROJECTS}
                initialFilters={{
                    project_member: '',
                    project_manager: '',
                    is_active: true,
                    from_date: '',
                    due_date: '',
                    is_template: false,
                }}
                listAdditionActions={(data) => [
                    {
                        as: 'filter',
                        index: 0,
                        name: t('active'),
                        filters: {
                            is_active: true,
                        },
                    },
                    {
                        as: 'filter',
                        index: 1,
                        name: t('closed'),
                        filters: {
                            is_active: false,
                        },
                    },
                    {
                        as: 'filter',
                        index: 3,
                        name: t('all'),
                        filters: {
                            is_active: null,
                        },
                    },
                ]}
                renderFilterFields={(filters, setFilters) => (
                    <>
                        <Form.Group widths="equal">
                            <SuperField
                                as="datepicker"
                                size="small"
                                label={t('date_from')}
                                value={filters.from_date}
                                style={{ height: '2.75rem' }}
                                onChange={(e, { value }) => setFilters({ ...filters, from_date: value })}
                            />
                            <SuperField
                                as="datepicker"
                                size="small"
                                label={t('date_to')}
                                value={filters.due_date}
                                style={{ height: '2.75rem' }}
                                onChange={(e, { value }) => setFilters({ ...filters, due_date: value })}
                            />
                        </Form.Group>
                        <DatePresets
                            from={filters.from_date}
                            to={filters.due_date}
                            onSelection={(firstMonthDay, lastMonthDay, year) => {
                                setFilters((prev) => ({
                                    ...prev,
                                    from_date: firstMonthDay,
                                    due_date: lastMonthDay,
                                }))
                            }}
                        />
                        <Divider />
                        <SuperField
                            as="choice"
                            search
                            text="fullname"
                            label={t('project_member')}
                            value={filters.project_member}
                            onChange={(e, { value }) => setFilters({ ...filters, project_member: value })}
                            endpoint={API.EMPLOYEES + '?only_basic_info=true'}
                        />
                        <SuperField
                            as="choice"
                            search
                            text="fullname"
                            label={t('project_manager')}
                            value={filters.project_manager}
                            onChange={(e, { value }) => setFilters({ ...filters, project_manager: value })}
                            endpoint={API.EMPLOYEES + '?only_basic_info=true'}
                        />
                    </>
                )}
                actions={[
                    {
                        as: 'modal',
                        type: 'add',
                        name: t('create_project'),
                        modal: <ProjectForm />,
                        permissions: ['projects.c_manage_all_projects'],
                    },
                    {
                        as: 'link',
                        type: 'custom',
                        icon: 'eye-outline',
                        customIconColor: 'var(--primary)',
                        name: t('show_detail'),
                        redirect: (item) => routes.PROJECT_DETAIL + item.id,
                    },
                    {
                        as: 'modal',
                        type: 'edit',
                        name: t('edit'),
                        modalSize: 'small',
                        permissions: ['projects.c_manage_all_projects', 'projects.c_manage_user_projects'],
                        modal: <ProjectForm />,
                    },
                    {
                        name: t('delete'),
                        type: 'delete',
                        as: 'delete',
                        text: t('delete_project'),
                        permissions: ['projects.c_delete_all_projects'],
                    },
                ]}
                tableHeaders={[
                    { title: t('name'), orderBy: 'name' },
                    { title: t('identifier') },
                    { title: t('date_from') },
                    { title: t('date_to') },
                    { title: t('responsible_person') },
                    { title: t('status') },
                ]}
                renderCells={(project) => [
                    {
                        content: (
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Label
                                    circular
                                    style={{ background: project?.color, minHeight: '0', marginRight: '0.5rem' }}
                                />
                                <strong>{project?.name}</strong>
                            </div>
                        ),
                    },
                    {
                        content: project?.identifier,
                    },
                    { content: project.from_date ? moment(project.from_date).format(dateFormat) : '' },
                    { content: project.due_date ? moment(project.due_date).format(dateFormat) : '' },
                    {
                        content: project.project_manager && (
                            <AvatarList
                                size="25"
                                alignDirection="start"
                                persons={[project.project_manager].map((item) => ({
                                    id: item.id,
                                    name: item.fullname,
                                    avatar: item.profile_picture,
                                    displayName: item?.fullname_with_titles || item.fullname,
                                }))}
                            />
                        ),
                    },
                    {
                        content: (
                            <Label
                                size="small"
                                style={{
                                    background: project?.is_active ? 'var(--success)' : 'var(--danger)',
                                    color: 'var(--white)',
                                }}
                            >
                                {project?.is_active ? t('active') : t('closed')}
                            </Label>
                        ),
                    },
                ]}
            />
        </CanView>
    )
}

export default ProjectList
