import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
// store
import { routes } from '@routes'
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { patchRequest } from '@services/ServiceCommon'
// components
import Icon from '@components/Icon'
import AvatarList from '@components/AvatarList'
import Attachments from '@components/Attachments'
import LabelsList from '@components/lists/LabelsList'
import SuperField from '@components/forms/SuperField'
import { Dropdown, Form, Menu, Popup, Label } from 'semantic-ui-react'
// component specific
import ListView from '@components/ListView'
import CaseForm from '../components/CaseForm'
import CaseDetail from '../components/CaseDetail'

const CasesList = () => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)
    const colors = useSelector((state) => state.global_pref.colors)
    const statuses = useSelector((state) => state.choices.case_status_choices)

    const [attachmentsOpen, setAttachmentsOpen] = useState(0)

    const options = [
        { key: 1, text: statuses[1], value: 1 },
        { key: 2, text: statuses[2], value: 2 },
        { key: 3, text: statuses[3], value: 3 },
        { key: 4, text: statuses[4], value: 4 },
    ]

    const [statsNew, setStatsNew] = useState(0)
    const [statsAssigned, setStatsAssigned] = useState(0)
    const [statsPending, setStatsPending] = useState(0)
    const [statsClosed, setStatsClosed] = useState(0)
    const [statsAll, setStatsAll] = useState(0)

    async function fetchStats() {
        const request = await requests.get(API.REPORTS + `cases/stats/`)
        if (request.status === 200) {
            setStatsAll(request.response.total_cases)
            setStatsNew(request.response.total_new_cases)
            setStatsAssigned(request.response.total_assigned_cases)
            setStatsPending(request.response.total_pending_cases)
            setStatsClosed(request.response.total_closed_cases)
        }
    }

    useEffect(() => {
        fetchStats()
        // eslint-disable-next-line
    }, [])

    const onCreate = (response, setData, setTotal) => {
        setData((prev) => [response, ...prev])
        setTotal((prev) => prev + 1)
    }

    const onUpdate = async (response, setData, setTotal) => {
        setData((prev) =>
            prev.map((cases) => {
                if (cases.id === response.id) {
                    cases = response
                }
                return cases
            })
        )

        // if(response.status === 1 || response.status === 2){
        await fetchStats()
        //     setData((prev) => prev.filter((item) => item.id !== response.id))
        // }
    }

    const onUpdateStatus = (status, response, setData, setTotal) => {
        setData((prev) => prev.filter((item) => item.id !== response.id))
        setTotal((prev) => prev - 1)
        if (status === 1) {
            setStatsNew((prev) => prev - 1)
        }
        if (status === 2) {
            setStatsAssigned((prev) => prev - 1)
        }
        if (status === 3) {
            setStatsPending((prev) => prev - 1)
        }
        if (status === 4) {
            setStatsClosed((prev) => prev - 1)
        }
        if (response.status === 1) {
            setStatsNew((prev) => prev + 1)
        }
        if (response.status === 2) {
            setStatsAssigned((prev) => prev + 1)
        }
        if (response.status === 3) {
            setStatsPending((prev) => prev + 1)
        }
        if (response.status === 4) {
            setStatsClosed((prev) => prev + 1)
        }
    }

    function priorityColor(priority) {
        if (priority === 1) return colors.success
        if (priority === 2) return colors.primary
        if (priority === 3) return colors.warning
        if (priority === 4) return colors.danger
    }

    return (
        <ListView
            as="table"
            allowSearch
            endpoint={API.CASES}
            isSoftDeleteAllowed
            initialFilters={{
                account: '',
                label: '',
                priority: '',
                status: '1',
                type: '',
                ordering: '-created_on',
            }}
            renderFilterFields={(filters, setFilters) => (
                <>
                    <SuperField
                        as="choice"
                        search
                        label={t('account')}
                        endpoint={
                            API.ACCOUNTS +
                            'business_details/?query={id, name}&is_account=true&exclude_unit=true&is_active=true'
                        }
                        text="name"
                        value={filters.account}
                        onChange={(e, { value }) =>
                            setFilters({
                                ...filters,
                                account: value,
                            })
                        }
                    />
                    <SuperField
                        as="choice"
                        search
                        multiple
                        value={filters.label}
                        label={t('labels')}
                        endpoint={API.CASES_LABELS}
                        text="name"
                        onChange={(e, { value }) =>
                            setFilters({
                                ...filters,
                                label: value,
                            })
                        }
                    />
                    <Form.Group widths="equal">
                        <SuperField
                            as="choice"
                            type="priority_choices"
                            label={t('priority')}
                            value={filters.priority}
                            onChange={(e, { value }) =>
                                setFilters({
                                    ...filters,
                                    priority: value,
                                })
                            }
                        />
                        <SuperField
                            as="choice"
                            type="case_status_choices"
                            label={t('status')}
                            value={filters.status}
                            onChange={(e, { value }) =>
                                setFilters({
                                    ...filters,
                                    status: value,
                                })
                            }
                        />
                        <SuperField
                            as="choice"
                            search
                            label={t('type')}
                            endpoint={API.CASES_TYPES}
                            text="title"
                            value={filters.type || ''}
                            onChange={(e, { value }) =>
                                setFilters({
                                    ...filters,
                                    type: value,
                                })
                            }
                        />
                    </Form.Group>
                </>
            )}
            actions={[
                {
                    as: 'modal',
                    type: 'add',
                    name: t('create_case'),
                    modalSize: 'small',
                    permissions: ['cases.c_manage_all_cases', 'cases.c_manage_assigned_user_cases'],
                    modal: (
                        <CaseForm
                            handleRequest={(response, setData, setTotal) => onCreate(response, setData, setTotal)}
                        />
                    ),
                },
                {
                    type: 'sidebar',
                    icon: 'eye-outline',
                    name: t('show_detail'),
                    customIconColor: 'var(--primary)',
                    content: (item, setData, setTotal) => (
                        <CaseDetail
                            record={item}
                            setData={setData}
                            setTotal={setTotal}
                            onUpdateStatus={onUpdateStatus}
                            options={options}
                        />
                    ),
                    permissions: [],
                },
                {
                    as: 'modal',
                    type: 'edit',
                    name: t('edit'),
                    modalSize: 'small',
                    permissions: ['cases.c_manage_all_cases', 'cases.c_manage_assigned_user_cases'],
                    modal: (item, setData) => (
                        <CaseForm
                            data={item}
                            setData={setData}
                            handleRequest={(response, setData, setTotal) => onUpdate(response, setData, setTotal)}
                        />
                    ),
                },
                {
                    name: t('delete'),
                    type: 'delete',
                    as: 'delete',
                    text: t('delete'),
                    permissions: ['cases.c_delete_all_cases'],
                },
            ]}
            exports={[
                {
                    endpoint: API.EXPORTS + 'dynamic_cases/',
                    permissions: ['cases.c_view_all_cases'],
                    apply_filters: true,
                    type: 'xlsx',
                    filename: 'cases',
                },
            ]}
            tableHeaders={[
                { title: t('id'), orderBy: 'id' },
                { title: t('title'), orderBy: 'title' },
                { title: t('priority'), orderBy: 'priority' },
                { title: t('account') },
                { title: t('created_on'), orderBy: 'created_on' },
                { title: t('due_date'), orderBy: 'created_on' },
                { title: t('labels') },
                { title: t('assigned') },
                { title: t('documents') },
                { title: t('status') },
            ]}
            listAdditionActions={() => [
                {
                    as: 'filter',
                    index: 0,
                    name: t('new'),
                    filters: {
                        status: 1,
                    },
                    count: statsNew || 0,
                },
                {
                    as: 'filter',
                    index: 1,
                    name: t('assigned'),
                    filters: {
                        status: 2,
                    },
                    count: statsAssigned || 0,
                },
                {
                    as: 'filter',
                    index: 2,
                    name: t('pending'),
                    filters: {
                        status: 3,
                    },
                    count: statsPending || 0,
                },
                {
                    as: 'filter',
                    index: 3,
                    name: t('closed'),
                    filters: {
                        status: 4,
                    },
                    count: statsClosed || 0,
                },
                {
                    as: 'filter',
                    index: 4,
                    name: t('all'),
                    filters: {
                        status: '',
                    },
                    count: statsAll || 0,
                },
            ]}
            renderCells={(cases, setData, setTotal, idx) => [
                { content: cases?.id },
                {
                    content: (
                        <>
                            <strong style={{ fontSize: '1rem' }}>{cases?.title}</strong>
                            <br />
                            <span>{cases?.type?.title || ''}</span>
                        </>
                    ),
                },
                {
                    content: (
                        <Label
                            style={{
                                background: priorityColor(cases?.priority),
                                color: 'var(--white)',
                                marginRight: '0.5rem',
                            }}
                        >
                            {cases?.priority_display}
                        </Label>
                    ),
                },
                {
                    content: cases?.business_detail?.name ? (
                        <Link
                            target="_blank"
                            rel="noopener noreferrer"
                            to={routes.ACCOUNTS + cases?.business_detail?.id}
                        >
                            <span>{cases?.business_detail?.name}</span>
                        </Link>
                    ) : (
                        '--'
                    ),
                },
                { content: cases?.created_on && moment(cases?.created_on).format(dateFormat) },
                { content: cases?.due_date && moment(cases?.due_date).format(dateFormat) },
                {
                    content: (
                        <LabelsList
                            rowID={cases?.id}
                            labels={cases?.label}
                            endpoint={API.CASES}
                            endpointLabels={API.CASES_LABELS}
                            label_key="label"
                        />
                    ),
                },
                {
                    content: (
                        <AvatarList
                            size="25"
                            alignDirection="start"
                            persons={cases.assigned_to.map((item) => ({
                                id: item?.id,
                                name: item?.fullname || '--',
                                avatar: item?.profile_picture,
                                displayName: item?.fullname_with_titles || item?.fullname || '--',
                            }))}
                        />
                    ),
                },
                {
                    content: (
                        <Label basic>
                            <Popup
                                basic
                                style={{ zIndex: '1000', minWidth: '450px' }}
                                open={attachmentsOpen === cases.id}
                                onOpen={() => setAttachmentsOpen(cases.id)}
                                hoverable
                                position="left center"
                                trigger={
                                    <div style={{ cursor: 'pointer' }}>
                                        <Icon name="reader-outline" style={{ marginRight: '0.5rem' }} />
                                        <span style={{ position: 'relative', top: '-0.1rem' }}>
                                            {cases.attachments.length}
                                        </span>
                                    </div>
                                }
                                content={
                                    <Attachments
                                        // viewOnly={!canManage}
                                        allowFolders
                                        record={cases}
                                        moduleName={'cases'}
                                        setData={setData}
                                        sourceEndpoint={API.CASES}
                                        closeView={() => setAttachmentsOpen(0)}
                                    />
                                }
                            />
                        </Label>
                    ),
                    // <Action as="modal"
                    //     modalSize="tiny"
                    //     tooltip={t('documents')}
                    //     open={cases.id === forceModalOpen}
                    //     onClose={() => setForceModalOpen(0)}
                    //     trigger={
                    //         <span style={{ fontSize: "1.2rem", cursor: "pointer" }} onClick={() => setForceModalOpen(cases.id)}>
                    //             <Icon name="document-outline"/>
                    //             <sup style={{ fontWeight: "bold", marginLeft: "0.5rem" }}>{ cases.attachments.length }</sup>
                    //         </span>
                    //     }
                    //     content={
                    //         <CaseDocuments caseID={cases.id} attachments={cases.attachments} setData={setData}/>
                    //     }
                    // />
                },
                {
                    content: (
                        <>
                            <div style={{ display: 'flex', width: '60px', zIndex: 1 + idx }}>
                                <Menu compact style={{ background: 'transparent', border: 'none', boxShadow: 'none' }}>
                                    <Dropdown
                                        compact
                                        options={[
                                            { key: 1, text: statuses[1], value: 1 },
                                            {
                                                key: 2,
                                                text: statuses[2],
                                                value: 2,
                                                disabled: cases?.assigned_to.length === 0 ? true : false,
                                            },
                                            { key: 3, text: statuses[3], value: 3 },
                                            { key: 4, text: statuses[4], value: 4 },
                                        ]}
                                        selection
                                        value={cases?.status}
                                        onChange={async (event, data) => {
                                            const request = await patchRequest(`${API.CASES}${cases.id}/`, {
                                                status: data.value,
                                            })
                                            if (request.status === 200) {
                                                toast({
                                                    type: 'success',
                                                    icon: 'check circle',
                                                    title: t('status_changed'),
                                                    description: '',
                                                    animation: 'bounce',
                                                    time: 2000,
                                                })
                                                onUpdateStatus(cases?.status, request.response, setData, setTotal)
                                            }
                                        }}
                                        style={{
                                            background: 'transparent',
                                            width: '100px',
                                            border: 'none',
                                            fontSize: '12px',
                                            marginTop: '0.3rem',
                                            position: 'absolute',
                                        }}
                                    />
                                </Menu>
                            </div>
                        </>
                    ),
                },
            ]}
        />
    )
}

export default CasesList
