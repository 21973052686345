import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
import { useSelector } from 'react-redux'
import moment from 'moment'
import '@store/languages'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { useHasPermission } from '@helpers/hooks'
import { setLocaleLanguage, tzDateTime } from '@helpers/dates'
// components
import ListView from '@components/ListView'
import CanView from '@components/perms/CanView'
import DatePresets from '@components/DatePresets'
import PayrollForm from '../components/PayrollForm'
import SuperField from '@components/forms/SuperField'
import { Form, Label, Table, Popup } from 'semantic-ui-react'
// import PayrollEditForm from '../components/PayrollEditForm'
import PayrollDetails from '../components/PayrollDetails'

const PayrollsList = () => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)

    // eslint-disable-next-line
    const profile = useSelector((state) => state.user.profile)

    // eslint-disable-next-line
    const canManageOnlyAssignedEmployees = useHasPermission(
        'attendance.c_can_manage_only_employees_as_responsible_person'
    )

    let isResponsible = ''
    if (canManageOnlyAssignedEmployees && profile?.id) {
        isResponsible = '&responsible_person=' + profile.id
    }

    // const firstDay = moment().startOf('month').format('YYYY-MM-DD')
    // const lastDay = moment().endOf('month').format('YYYY-MM-DD')

    async function bulkAction(isClosed, selected, setData, action) {
        let datalist = []
        for (let i = 0; i < selected.length; i++) {
            if (selected[i].is_closed === true && action === 'close') continue
            const request = await requests.patch(API.PAYROLLS + selected[i].id + '/', {
                is_closed: action === 'close' ? true : false,
            })
            if (request.status === 200) {
                datalist.push(request.response.id)
            }
        }

        setData((prev) => prev.filter((item) => !datalist.includes(item.id)))
        toast({
            type: 'success',
            icon: 'check circle',
            title: t('payroll_records_updated'),
            animation: 'pulse',
            time: 2000,
        })
    }

    const calculateSum = (data) => {
        let sum = 0

        for (let i = 0; i < data.length; i++) {
            sum += parseFloat(data[i].amount)
        }

        return sum
    }

    return (
        <CanView permissions={['payrolls.c_view_all_payrolls', 'payrolls.c_manage_user_payrolls']} redirect>
            <ListView
                as="table"
                allowSearch
                // isSoftDeleteAllowed
                allowSelection
                allowRowExport
                endpoint={API.PAYROLLS}
                actionsCellWidth="2"
                initialFilters={{
                    date_from: '',
                    date_to: '',
                    profile: '',
                    employer: '',
                    contract_type: '',
                    unit: '',
                    is_closed: false,
                    ordering: ['-date_from'],
                }}
                renderFilterFields={(filters, setFilters) => (
                    <>
                        <Form.Group widths="equal">
                            <SuperField
                                search
                                as="datepicker"
                                label={t('date_from')}
                                value={filters.date_from}
                                onChange={(e, { value }) => {
                                    setFilters((prev) => ({ ...prev, date_from: value }))
                                }}
                            />
                            <SuperField
                                search
                                as="datepicker"
                                label={t('date_to')}
                                value={filters.date_to}
                                onChange={(e, { value }) => {
                                    setFilters((prev) => ({ ...prev, date_to: value }))
                                }}
                            />
                        </Form.Group>

                        <DatePresets
                            open={true}
                            from={filters.date_from}
                            to={filters.date_to}
                            onSelection={(firstMonthDay, lastMonthDay, year) => {
                                setFilters((prev) => ({
                                    ...prev,
                                    date_from: firstMonthDay,
                                    date_to: lastMonthDay,
                                    year: year,
                                }))
                            }}
                        />

                        <Form.Group widths="equal">
                            <SuperField
                                search
                                as="choice-select"
                                label={t('employee')}
                                endpoint={API.EMPLOYEES}
                                additionalFilters={`&only_basic_info=true&is_active=true${isResponsible}`}
                                text="fullname"
                                value={filters.profile}
                                onChange={(e, { value }) => {
                                    setFilters({ ...filters, profile: value })
                                }}
                            />
                            <SuperField
                                as="choice-select"
                                search
                                label={t('employer')}
                                value={filters.employer}
                                text="name"
                                endpoint={API.BUSINESS_DETAIL}
                                additionalFilters={'&query={id, name}&is_employer=true'}
                                onChange={(e, { value }) => setFilters({ ...filters, employer: value })}
                            />
                        </Form.Group>

                        <Form.Group widths="equal">
                            <SuperField
                                label={t('department')}
                                as="choice-select"
                                endpoint={API.UNITS}
                                additionalFilters="&query={id, name}&only_basic_info=true&only_from_active_company=true"
                                text="name"
                                value={filters.unit}
                                onChange={(e, { value }) => setFilters({ ...filters, unit: value })}
                            />

                            <SuperField
                                as="choice-select"
                                label={t('contract_type')}
                                endpoint={API.CONTRACTS_TYPES}
                                additionalFilters="&only_basic_info=true"
                                text="title"
                                value={filters.contract_type}
                                onChange={(e, { value }) => setFilters({ ...filters, contract_type: value })}
                            />
                        </Form.Group>
                    </>
                )}
                exports={[
                    {
                        title: t('payroll_records__full'),
                        endpoint: API.EXPORTS + 'dynamic_extended_payrolls/',
                        permissions: ['payrolls.c_view_all_payrolls'],
                        apply_filters: true,
                        type: 'xlsx',
                        filename: t('payroll_records'),
                    },
                    {
                        title: t('payroll_records__simplified'),
                        endpoint: API.EXPORTS + 'simplified_dynamic_payrolls/',
                        permissions: ['payrolls.c_view_all_payrolls'],
                        apply_filters: true,
                        type: 'xlsx',
                        filename: t('payroll_records'),
                    },
                ]}
                listAdditionActions={(data, setData, total, setTotal, response) => [
                    {
                        as: 'filter',
                        index: 0,
                        name: t('open'),
                        filters: {
                            is_closed: false,
                        },
                        // count: response?.total_active || 0
                    },
                    {
                        as: 'filter',
                        index: 1,
                        name: t('closed'),
                        filters: {
                            is_closed: true,
                        },
                        // count: response?.total_inactive || 0
                    },
                    {
                        as: 'filter',
                        index: 2,
                        name: t('all'),
                        filters: {
                            is_closed: null,
                        },
                        // count: response?.total || 0
                    },
                ]}
                bulkActions={(selected, setData, setTotal, filters, queryParams, setSelected) => [
                    {
                        as: 'custom',
                        name: t('close_payroll_record'),
                        icon: 'close-circle',
                        isConfirm: true,
                        confirmDescription: t('close_payroll_records_are_you_sure'),
                        onClick: () => bulkAction(filters.is_closed, selected, setData, 'close'),
                    },
                ]}
                actions={[
                    {
                        as: 'modal',
                        type: 'add',
                        name: t('add_payroll_record'),
                        modalSize: 'tiny',
                        modal: <PayrollForm />,
                        permissions: ['payrolls.c_manage_all_payrolls', 'payrolls.c_manage_user_payrolls'],
                    },
                    {
                        as: 'modal',
                        type: 'custom',
                        name: t('show_detail'),
                        icon: 'eye-outline',
                        customIconColor: 'var(--primary)',
                        permissions: ['payrolls.c_view_all_payrolls', 'payrolls.c_view_user_payrolls'],
                        modalSize: 'small',
                        modal: (item) => <PayrollDetails payrollID={item.id} />,
                    },
                    // {
                    //     as: 'modal',
                    //     type: 'edit',
                    //     name: t('edit'),
                    //     modalSize: 'tiny',
                    //     modal: <PayrollEditForm />,
                    //     disabled: (item) => item.is_closed,
                    //     permissions: ['payrolls.c_manage_all_payrolls', 'payrolls.c_manage_user_payrolls'],
                    // },
                    // {
                    //     name: t('delete'),
                    //     type: 'delete',
                    //     as: 'delete',
                    //     // disabled: (item) => item.is_closed,
                    //     isHidden: (item) => item.is_closed,
                    //     text: t('delete_payroll_record'),
                    //     permissions: ['payrolls.c_delete_all_payrolls'],
                    // },
                ]}
                tableHeaders={[
                    { title: t('employee') },
                    { title: t('payroll_period') },
                    { title: t('working_hours') + ' / ' + t('days').toLocaleLowerCase() },
                    { title: t('rewards') },
                    { title: t('premiums') },
                    { title: t('functional_surcharges') },
                    { title: t('advances') },
                    { title: t('deductions') },
                    // { title: t('approval_status') },
                    { title: t('created') },
                    { title: t('status') },
                ]}
                renderCells={(payroll) => [
                    {
                        content: (
                            <>
                                <strong style={{ fontSize: '1rem' }}>
                                    {payroll?.contract?.employee?.fullname_with_titles}
                                </strong>{' '}
                                <br />
                                <span style={{ opacity: '0.8' }}>
                                    {payroll?.contract?.employee?.custom_prefix_and_id || '--'}
                                </span>
                            </>
                        ),
                    },
                    {
                        content: (
                            <>
                                <strong style={{ fontSize: '1rem', fontWeight: 'bold', textTransform: 'capitalize' }}>
                                    {moment(payroll.date_from).locale(setLocaleLanguage()).format('MMMM')}{' '}
                                    {moment(payroll.date_from).format('YYYY')}
                                </strong>
                                <br />
                                <span style={{ opacity: '0.8' }}>
                                    {moment(payroll.date_from).format(dateFormat) +
                                        ' - ' +
                                        moment(payroll.date_to).format(dateFormat)}
                                </span>
                            </>
                        ),
                    },
                    {
                        content: (
                            <Popup
                                position="left center"
                                trigger={
                                    <div>
                                        {payroll.hours_total} / {payroll.total_days || 0}
                                    </div>
                                }
                                content={
                                    <Table singleLine size="small" style={{ background: 'transparent' }}>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell style={{ fontWeight: 'bold' }}>{t('saturday')}:</Table.Cell>
                                                <Table.Cell style={{ textAlign: 'right' }}>
                                                    {payroll.saturday_hours} / {payroll.total_saturdays}
                                                </Table.Cell>
                                                <Table.Cell style={{ fontWeight: 'bold' }}>{t('sunday')}:</Table.Cell>
                                                <Table.Cell style={{ textAlign: 'right' }}>
                                                    {payroll.sunday_hours} / {payroll.total_sundays}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell style={{ fontWeight: 'bold' }}>{t('holidays')}:</Table.Cell>
                                                <Table.Cell style={{ textAlign: 'right' }}>
                                                    {payroll.holiday_hours} / {payroll.total_holiday}
                                                </Table.Cell>
                                                <Table.Cell style={{ fontWeight: 'bold' }}>
                                                    {t('night_hours')}:
                                                </Table.Cell>
                                                <Table.Cell style={{ textAlign: 'right' }}>
                                                    {payroll.night_hours || 0} / -
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell style={{ fontWeight: 'bold' }}>
                                                    {t('total_hours')}:
                                                </Table.Cell>
                                                <Table.Cell style={{ textAlign: 'right' }}>
                                                    {payroll.hours_total} / {payroll.total_days || 0}
                                                </Table.Cell>
                                                <Table.Cell style={{ fontWeight: 'bold' }}>--</Table.Cell>
                                                <Table.Cell style={{ textAlign: 'right' }}></Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                }
                            />
                        ),
                    },
                    { content: payroll.rewards || 0 },
                    { content: calculateSum(payroll.bonuses || []) },
                    { content: payroll.functional_surcharges || 0 },
                    { content: calculateSum(payroll.advances || []) },
                    { content: calculateSum(payroll.deductions || []) },
                    {
                        content: (
                            <div>
                                {tzDateTime(payroll.created_on).format(dateFormat + ' HH:mm')} <br />
                                <span>
                                    {t('created_by')} <strong>{payroll.created_by?.name || '--'}</strong>
                                </span>
                            </div>
                        ),
                    },
                    {
                        content: !payroll.is_closed ? (
                            <Label color="green">{t('open')}</Label>
                        ) : (
                            <Label color="red">{t('closed')}</Label>
                        ),
                    },
                ]}
            />
        </CanView>
    )
}

export default PayrollsList
