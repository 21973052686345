import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
// store
import { API } from '@store/config'
import { routes } from '@store/routes'
import { requests } from '@helpers/requests'
import { useSelectOptions } from '@helpers/hooks'
//import { getCountryCodesAlpha3, normalizeString } from '@helpers/functions'
import { isEmpty, isPhoneNumberValid, isEmailValid } from '@helpers/validation'
// components
import Icon from '@components/Icon'
import Address from '@components/forms/Address'
import SuperField from '@components/forms/SuperField'
import ModalSubmit from '@components/buttons/ModalSubmit'
import ModalCancel from '@components/buttons/ModalCancel'
import PhoneField from '@components/forms/common/PhoneField'
import EmailField from '@components/forms/common/EmailField'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import SuperLabelField from '@components/forms/SuperLabelField'
import { Divider, Form, Header, Button, Label } from 'semantic-ui-react'

const LabelCandidateForm = ({ onClose, labels, setLabels }) => {
    const { t } = useTranslation()
    return (
        <Form onSubmit={(e) => e.preventDefault()}>
            <SuperLabelField
                value={labels}
                onChange={(labels, selectedLabels) => setLabels(selectedLabels)}
                endpoint={API.CONTACT_LABELS}
            />
            <Form.Field style={{ textAlign: 'right' }}>
                <Button type="button" primary content={t('confirm')} onClick={onClose} />
            </Form.Field>
        </Form>
    )
}

// const countryCodes = getCountryCodesAlpha3()

const CandidateFormx = ({ onClose, setSize, isHiringRoom, onCreate, parsedData, storeIdentityDocuments }) => {
    const { t } = useTranslation()
    const history = useHistory()

    // fetch model language choices
    let [languageNames] = useSelectOptions(API.COMMON + 'language_names/', 'title')
    let language_choices = languageNames?.options || []

    // eslint-disable-next-line
    const [form, setForm] = useState({
        firstname: '',
        lastname: '',
        is_foreigner: false,
        is_self_employed: false,
        phone: '',
        email: '',
        gender: 3,
        marital_status: '',
        nationality: '',
        citizenship: '',
        date_of_birth: '',
        place_of_birth: '',
        personal_number: '',
        id_card_number: '',
        permanent_same_as_temporary: false,
        emergency_name: '',
        emergency_email: '',
        emergency_phone: '',

        // business details
        name: '',
        id_number: '', // IČO (identification_number)
        dit_number: '', // DIČ (DIT Number)
        vat_number: '', // IČ DPH (VAT Number) - optional
        nace_code: '',
        folder_details: '', // business folder identifier
        tax_mode: '',
    })

    const [permanentAddress, setPermanentAddress] = useState({
        street: '',
        city: '',
        postcode: '',
        country: '',
        number: '',
        orientation_number: '',
        state: '',
    })

    const [temporaryAddress, setTemporaryAddress] = useState({
        street: '',
        city: '',
        postcode: '',
        country: '',
        number: '',
        orientation_number: '',
        state: '',
    })

    const initialSkill = { name: '', seniority_level: '' }
    const [skills, setSkills] = useState([initialSkill])

    const initialLanguages = {
        language_name: '',
        level: '',
        is_primary: false,
        is_native: false,
    }
    const [languages, setLanguages] = useState([initialLanguages])
    const initialExperience = {
        job_title: '',
        employer: '',
        date_from: '',
        date_to: '',
        description: '',
    }
    const [experiences, setExperiences] = useState([initialExperience])
    const initialEducation = {
        name: '',
        education_type: '',
        department: '',
        field: '',
        date_from: '',
        date_to: '',
    }
    const [educations, setEducations] = useState([initialEducation])

    const initialInterest = { name: '' }
    const [interests, setInterests] = useState([initialInterest])
    const [labels, setLabels] = useState([])
    const [cv, setCV] = useState(null)

    const [errors, setErrors] = useState(null)
    const [isProcessing, setIsProcessing] = useState(false)
    const [additionalPersonInfoVisible, setAdditionalPersonInfoVisible] = useState(false)
    const [permanentAddressVisible, setPermanentAddressVisible] = useState(false)
    const [temporaryAddressVisible, setTemporaryAddressVisible] = useState(false)
    const [businessDetailsVisible, setBusinessDetailsVisible] = useState(false)
    const [skillsVisibility, setSkillsVisibility] = useState(false)
    const [languagesVisibility, setLanguagesVisibility] = useState(false)
    const [experiencesVisibility, setExperiencesVisibility] = useState(false)
    const [educationsVisibility, setEducationsVisibility] = useState(false)
    const [interestsVisibility, setInterestsVisibility] = useState(false)

    useEffect(() => {
        if (parsedData) {
            const resume = parsedData.find((item) => item.type === 'resume')
            const identityDocument = parsedData.find((item) => item.type !== 'resume')

            const formData = form
            const permAddress = permanentAddress
            let interestsList = []
            let educationsList = []
            let experiencesList = []
            let languagesList = []
            let skillsList = []

            if (resume) {
                const resumeData = resume.result
                formData.firstname = resumeData?.firstname || ''
                formData.lastname = resumeData?.lastname || ''
                formData.gender = resumeData?.gender || ''
                formData.email = resumeData?.email || ''
                formData.phone = resumeData?.phone || ''
                formData.date_of_birth = resumeData?.date_of_birth || ''

                permAddress.street = resumeData?.address?.street || ''
                permAddress.number = resumeData?.address?.number || ''
                permAddress.postcode = resumeData?.address?.postcode || ''
                permAddress.orientation_number = resumeData?.address?.orientation_number || ''
                permAddress.city = resumeData?.address?.city || ''
                permAddress.country = resumeData?.address?.country || ''

                if (resumeData?.interests?.length > 0) {
                    interestsList = resumeData?.interests?.map((interest) => ({ name: interest?.name }))
                }

                if (resumeData?.educations?.length > 0) {
                    educationsList = resumeData?.educations?.map((education) => ({
                        name: education?.school_name,
                        date_from: moment(education?.date_from).isValid() ? education?.date_from : '',
                        date_to: moment(education?.date_to).isValid() ? education?.date_to : '',
                        /* TODO: add other fields*/
                    }))
                }

                if (resumeData?.skills?.length > 0) {
                    skillsList = resumeData?.skills?.map((skill) => ({
                        name: skill?.name || '',
                        seniority_level: skill?.seniority_level || '',
                    }))
                }

                if (resumeData?.work_experiences?.length > 0) {
                    experiencesList = resumeData?.work_experiences?.map((experience) => ({
                        job_title: experience?.position || '',
                        employer: experience?.employer || '',
                        date_from: moment(experience?.date_from).isValid() ? experience?.date_from : '',
                        date_to: moment(experience?.date_to).isValid() ? experience?.date_to : '',
                        description: experience?.job_description || '',
                    }))
                }

                /*if (resumeData?.languages?.length > 0) {
                    languagesList = resumeData?.languages?.map(language => ({ 
                        name: language.name || "",
                        level: language.level || ""
                    }))
                }*/
            }

            if (identityDocument) {
                const documentData = identityDocument.result

                // override resume data
                formData.firstname = documentData?.firstname || ''
                formData.lastname = documentData?.lastname || ''
                formData.gender = documentData?.gender || ''
                formData.nationality = documentData?.nationality || ''
                formData.citizenship = documentData?.citizenship || ''
                formData.date_of_birth = documentData?.date_of_birth || ''
                formData.place_of_birth = documentData?.place_of_birth || ''
                formData.personal_number = documentData?.personal_number || ''
                formData.id_card_number = documentData?.document_number || ''

                // override
                permAddress.street = documentData?.address?.street || ''
                permAddress.number = documentData?.address?.number || ''
                permAddress.city = documentData?.address?.city || ''
                permAddress.country = documentData?.address?.country || ''
            }

            if (resume) {
                setCV(resume.file)
            }

            setForm(formData)
            setPermanentAddress(permanentAddress)
            setInterests(interestsList)
            setSkills(skillsList)
            setExperiences(experiencesList)
            setEducations(educationsList)
        }
    }, [])

    const onLabelRemove = (id) => {
        setLabels((prev) => prev.filter((label) => label.id !== id))
    }

    const handleSubmit = async () => {
        // define logic to be executed when the form is submitted
        setIsProcessing(true)
        setErrors(null)

        let experiences_data = []
        for (let i = 0; i < experiences.length; i++) {
            if (
                !isEmpty(experiences[i].job_title) &&
                isDateFromValid(experiences[i].date_from) &&
                isDateToValid(experiences[i].date_to)
            ) {
                experiences_data.push(experiences[i])
            }
        }

        let educations_data = []
        for (let i = 0; i < educations.length; i++) {
            if (
                isDateFromValid(educations[i].date_from) &&
                isDateToValid(educations[i].date_to) &&
                !isFutureDate(educations[i].date_from) &&
                !isFutureDate(educations[i].date_to)
            ) {
                educations_data.push({
                    ...educations[i],
                    education_type: educations[i].education_type ? educations[i].education_typ : null,
                })
            }
        }

        let skills_data = []
        for (let i = 0; i < skills.length; i++) {
            // tracking if skills is properly filled and if name is unique compare to other already added skills
            if (!isEmpty(skills[i].name) && skills_data.filter((item) => item.name === skills[i].name).length === 0) {
                skills_data.push(skills[i])
            }
        }

        let interests_data = []
        for (let i = 0; i < interests.length; i++) {
            if (!isEmpty(interests[i].name)) {
                interests_data.push(interests[i])
            }
        }

        let languages_data = []
        for (let i = 0; i < languages.length; i++) {
            if (!isEmpty(languages[i].language_name) && !isEmpty(languages[i].level)) {
                languages_data.push(languages[i])
            }
        }

        let data = {
            last_country_residence: null,
            emergency_name: form.emergency_name,
            emergency_email: form.emergency_email,
            emergency_phone: form.emergency_phone,
            is_foreigner: form.is_foreigner,
            id_card_number: form.id_card_number !== '' ? form.id_card_number : null,
            personal_number: form.personal_number !== '' ? form.personal_number : null,
            marital_status: form.marital_status !== '' ? form.marital_status : null,
            firstname: form.firstname,
            lastname: form.lastname,
            gender: isEmpty(form.gender) ? 3 : form.gender,
            date_of_birth: isEmpty(form.date_of_birth) ? null : form.date_of_birth,
            place_of_birth: form.place_of_birth,
            permanent_address: permanentAddress,
            temporary_address: temporaryAddress,
            business_detail: !form.is_self_employed
                ? null
                : {
                      name: form.name,
                      identification_number: form.id_number,
                      dit_number: form.dit_number,
                      vat_number: form.vat_number,
                      business_register_folder: form.folder_details,
                      nace_code: form.nace_code !== '' ? form.nace_code : null,
                      tax_mode: form.tax_mode !== '' ? form.tax_mode : null,
                  },
        }

        if (form.nationality) {
            data['nationality'] = form.nationality
        }

        if (form.citizenship) {
            data['citizenship'] = form.citizenship
        }

        if (!isEmpty(form.phone) || !isEmpty(form.email)) {
            data['contacts'] = {
                create: [{ phone: form?.phone || '', email: form?.email || '', is_default: true }],
            }
        }

        if (experiences_data.length > 0)
            data['experiences'] = {
                create: experiences_data.map((item) => {
                    if (item.date_from === '') item.date_from = null
                    if (item.date_to === '') item.date_to = null

                    return item
                }),
            }
        if (skills_data.length > 0)
            data['skills'] = {
                create: skills_data.map((item) => {
                    if (item.seniority_level === '') item.seniority_level = null

                    return item
                }),
            }
        if (educations_data.length > 0)
            data['educations'] = {
                create: educations_data.map((item) => {
                    if (item.date_from === '') item.date_from = null
                    if (item.date_to === '') item.date_to = null

                    return item
                }),
            }
        if (languages_data.length > 0) data['languages'] = { create: languages_data }
        if (interests_data.length > 0) data['interests'] = { create: interests_data }
        if (labels.length > 0) data['contact_labels'] = { add: labels.map((item) => item.id) }

        const request = await requests.post(API.CANDIDATES, data)
        if (request.status === 400) setErrors(request.response)
        if (request.status === 201) {
            if (cv !== null) {
                const formData = new FormData()
                formData.append('cv', cv)
                const uploadImage = await requests.post(API.CANDIDATES + request.response.id + '/cv/', formData)
                if (uploadImage.status === 200) {
                    let candidate = request.response
                    candidate.cv = uploadImage.response.cv
                }
            }

            const identityDocument = parsedData ? parsedData?.find((item) => item.type !== 'resume') : null
            if (identityDocument) {
                const identityDocuments = {
                    driver_licence: 1,
                    passport: 2,
                    residence_card: 3,
                    id_card: 4,
                    //idDocument: 5,
                }

                const documentType = identityDocuments?.[identityDocument?.type]
                if (documentType) {
                    const documentData = identityDocument.result
                    // handle identity document create
                    const requestDoc = await requests.post(API.IDENTITY_DOCUMENTS, {
                        type: documentType,
                        profile: request.response?.id,
                        document_number: documentData?.document_number || '',
                        valid_until: documentData?.date_of_expiration || null,
                        issued_date: documentData?.date_of_issue || null,
                        issued_country: documentData?.country_of_issue || null,
                        note: documentData?.note || '',
                    })

                    if (requestDoc.status === 201) {
                        if (storeIdentityDocuments) {
                            const formData = new FormData()
                            formData.append('image_front', identityDocument.frontfile)

                            if (identityDocument.backfile) {
                                formData.append('image_back', identityDocument.backfile)
                            }

                            await requests.patch(API.IDENTITY_DOCUMENTS + requestDoc.response.id + '/file/', formData)
                        }
                    }
                }
            }

            if (isHiringRoom) {
                if (onCreate) {
                    onCreate(request.response)
                    onClose()
                } else {
                    history.push(routes.CANDIDATES_CARD + request.response.id)
                }
            } else {
                history.push(routes.CANDIDATES_CARD + request.response.id)
            }
        }

        setIsProcessing(false)
    }

    const isFormValid = () => {
        // define rules to be checked against form
        if (isEmpty(form.firstname)) return false
        if (isEmpty(form.lastname)) return false
        if (!isEmpty(form.phone) && !isPhoneNumberValid(form.phone)) return false
        if (!isEmpty(form.email) && !isEmailValid(form.email)) return false
        if (isFutureDate(form.date_of_birth)) return false
        if (form.is_self_employed && isEmpty(form.name) && isEmpty(form.id_number)) return false

        return true
    }

    const isDateFromValid = (from, to) => {
        if (isEmpty(from) || isEmpty(to)) return true
        return !isEmpty(from) && !isEmpty(to) && moment(from).isBefore(moment(to)) ? true : false
    }

    const isDateToValid = (from, to) => {
        if (isEmpty(from) || isEmpty(to)) return true
        return !isEmpty(from) && !isEmpty(to) && moment(to).isAfter(moment(from)) ? true : false
    }

    const isFutureDate = (date) => {
        if (isEmpty(date)) return false
        const currentDay = moment()
        if (currentDay.isBefore(date, 'day')) {
            return true
        }

        return false
    }

    return (
        <Form onSubmit={handleSubmit} className="fluid" style={{ marginTop: '0.5rem' }}>
            {errors !== null && (
                <p
                    style={{
                        color: 'var(--danger)',
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fontSize: '1.4rem',
                    }}
                >
                    {t('form_containing_errors')}
                </p>
            )}
            <Header as="h3" content={t('personal_information')} />
            <Divider />
            <Form.Group widths="equal">
                <SuperField
                    as="input"
                    required
                    autoFocus
                    label={t('lastname')}
                    placeholder={t('lastname')}
                    value={form.lastname}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, lastname: value }))}
                    error={errors?.lastname || false}
                />
                <SuperField
                    as="input"
                    required
                    label={t('firstname')}
                    placeholder={t('firstname')}
                    value={form.firstname}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, firstname: value }))}
                    error={errors?.firstname || false}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <PhoneField
                    hideType
                    placeholder={t('enter_number')}
                    value={form.phone}
                    setValue={(e, { value }) =>
                        setForm({
                            ...form,
                            phone: value.replaceAll(' ', ''),
                        })
                    }
                />
                <EmailField
                    label={t('email')}
                    placeholder={t('enter_email')}
                    value={form.email}
                    setValue={(e, { value }) =>
                        setForm({
                            ...form,
                            email: value,
                        })
                    }
                />
            </Form.Group>

            <Form.Group>
                <SuperField
                    as="checkbox"
                    label={t('is_foreigner')}
                    checked={form.is_foreigner}
                    onChange={() => setForm((prev) => ({ ...prev, is_foreigner: !form.is_foreigner }))}
                />
                <SuperField
                    as="checkbox"
                    label={t('self_employed_singular')}
                    checked={form.is_self_employed}
                    onChange={() => setForm((prev) => ({ ...prev, is_self_employed: !form.is_self_employed }))}
                />
            </Form.Group>

            <Divider />
            <div
                style={{ marginBottom: '0.5rem', cursor: 'pointer' }}
                onClick={() => setAdditionalPersonInfoVisible(!additionalPersonInfoVisible)}
            >
                <Icon
                    name={!additionalPersonInfoVisible ? 'chevron-forward-outline' : 'chevron-down-outline'}
                    style={{ marginRight: '0.5rem', cursor: 'pointer' }}
                />
                <span style={{ position: 'relative', top: '-0.2rem' }}>{t('additional_personal_information')}</span>
            </div>
            <Divider />
            {additionalPersonInfoVisible && (
                <div>
                    <Form.Group widths="equal">
                        <SuperField
                            as="choice"
                            type="genders"
                            label={t('gender')}
                            value={form.gender?.toString() || ''}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, gender: value }))}
                        />
                        <SuperField
                            as="choice"
                            type="marital_statuses"
                            label={t('marital_status')}
                            value={form.marital_status?.toString() || ''}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, marital_status: value }))}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField
                            as="datepicker"
                            label={t('date_of_birth')}
                            startMode="year"
                            help={t('must_be_at_least_15_years_old')}
                            error={
                                isFutureDate(form.date_of_birth)
                                    ? t('date_cant_be_future')
                                    : errors?.date_of_birth?.[0] || false
                            }
                            value={form.date_of_birth}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, date_of_birth: value }))}
                        />
                        <SuperField
                            as="input"
                            label={t('place_of_birth')}
                            value={form.place_of_birth}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, place_of_birth: value }))}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField
                            as="input"
                            label={t('personal_number')}
                            value={form.personal_number}
                            error={errors?.personal_number?.[0] || false}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, personal_number: value }))}
                        />
                        <SuperField
                            as="input"
                            label={t('identity_card_number')}
                            help={t('identity_card_number_hint')}
                            value={form.id_card_number}
                            error={errors?.id_card_number?.[0] || false}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, id_card_number: value }))}
                        />
                    </Form.Group>

                    <Header as="h4" content={t('emergency_contact')} />
                    <SuperField
                        as="input"
                        label={t('emergency_name')}
                        value={form.emergency_name}
                        error={errors?.emergency_name?.[0] || false}
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, emergency_name: value }))}
                    />
                    <Form.Group widths="equal">
                        <SuperField
                            as="input"
                            label={t('emergency_phone')}
                            value={form.emergency_phone}
                            error={errors?.emergency_phone?.[0] || false}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, emergency_phone: value }))}
                        />
                        <SuperField
                            as="input"
                            label={t('emergency_email')}
                            value={form.emergency_email}
                            error={errors?.emergency_email?.[0] || false}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, emergency_email: value }))}
                        />
                    </Form.Group>
                    <Divider />
                </div>
            )}

            <div
                style={{ marginBottom: '0.5rem', cursor: 'pointer' }}
                onClick={() => setPermanentAddressVisible(!permanentAddressVisible)}
            >
                <Icon
                    name={!permanentAddressVisible ? 'chevron-forward-outline' : 'chevron-down-outline'}
                    style={{ marginRight: '0.5rem', cursor: 'pointer' }}
                />
                <span style={{ position: 'relative', top: '-0.2rem' }}>{t('permanent_address')}</span>
            </div>
            <Divider />
            {permanentAddressVisible && (
                <div>
                    <Address isForm address={permanentAddress} setAddress={setPermanentAddress} />
                    <SuperField
                        as="checkbox"
                        label={t('same_as_temporary_address')}
                        checked={form.permanent_same_as_temporary}
                        onChange={() => {
                            if (!form.permanent_same_as_temporary === true) {
                                setTemporaryAddress((prev) => ({
                                    ...prev,
                                    street: permanentAddress.street,
                                    city: permanentAddress.city,
                                    postcode: permanentAddress.postcode,
                                    country: permanentAddress.country,
                                    state: permanentAddress.state,
                                    number: permanentAddress.number,
                                    orientation_number: permanentAddress.orientation_number,
                                }))
                                setForm((prev) => ({
                                    ...prev,
                                    permanent_same_as_temporary: !form.permanent_same_as_temporary,
                                }))
                            } else {
                                setForm((prev) => ({
                                    ...prev,
                                    permanent_same_as_temporary: !form.permanent_same_as_temporary,
                                }))
                            }
                        }}
                    />
                    <Divider />
                </div>
            )}

            {form.permanent_same_as_temporary !== true && (
                <>
                    <div
                        style={{ marginBottom: '0.5rem', cursor: 'pointer' }}
                        onClick={() => setTemporaryAddressVisible(!temporaryAddressVisible)}
                    >
                        <Icon
                            name={!temporaryAddressVisible ? 'chevron-forward-outline' : 'chevron-down-outline'}
                            style={{ marginRight: '0.5rem', cursor: 'pointer' }}
                        />
                        <span style={{ position: 'relative', top: '-0.2rem' }}>{t('temporary_address')}</span>
                    </div>
                    <Divider />
                    {temporaryAddressVisible && (
                        <div>
                            <Address isForm address={temporaryAddress} setAddress={setTemporaryAddress} />
                            <Divider />
                        </div>
                    )}
                </>
            )}

            {form.is_self_employed && (
                <>
                    <div
                        style={{ marginBottom: '0.5rem', cursor: 'pointer' }}
                        onClick={() => setBusinessDetailsVisible(!businessDetailsVisible)}
                    >
                        <Icon
                            name={!businessDetailsVisible ? 'chevron-forward-outline' : 'chevron-down-outline'}
                            style={{ marginRight: '0.5rem', cursor: 'pointer' }}
                        />
                        <span style={{ position: 'relative', top: '-0.2rem' }}>
                            {t('business_detail')}
                            <span style={{ color: 'var(--danger)' }}>
                                {' '}
                                - {t('fill_business_name_id_number_to_procced')}
                            </span>
                        </span>
                    </div>
                    <Divider />
                    {businessDetailsVisible && (
                        <div>
                            <Form.Group widths="equal">
                                <SuperField
                                    as="input"
                                    required
                                    label={t('name')}
                                    placeholder={t('name')}
                                    value={form.name}
                                    onChange={(e, { value }) => setForm({ ...form, name: value })}
                                />
                                <SuperField
                                    as="choice"
                                    label={t('tax_mode')}
                                    type="tax_mode"
                                    value={form.tax_mode}
                                    onChange={(e, { value }) => setForm({ ...form, tax_mode: value })}
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <SuperField
                                    as="input"
                                    required
                                    label={t('id_number')}
                                    placeholder={t('id_number')}
                                    value={form.id_number}
                                    onChange={(e, { value }) => setForm({ ...form, id_number: value })}
                                />
                                <SuperField
                                    as="input"
                                    label={t('dit_number')}
                                    placeholder={t('dit_number')}
                                    value={form.dit_number}
                                    onChange={(e, { value }) => setForm({ ...form, dit_number: value })}
                                />
                                <SuperField
                                    as="input"
                                    label={t('vat_number')}
                                    placeholder={t('vat_number')}
                                    value={form.vat_number}
                                    onChange={(e, { value }) => setForm({ ...form, vat_number: value })}
                                />
                            </Form.Group>

                            <Form.Group widths="equal">
                                <SuperField
                                    as="input"
                                    label={t('register_folder')}
                                    placeholder={t('register_folder')}
                                    value={form.folder_details}
                                    onChange={(e, { value }) => setForm({ ...form, folder_details: value })}
                                />
                                <SuperField
                                    as="choice-select"
                                    search
                                    label={t('nace_code')}
                                    endpoint={API.NACE_CODE}
                                    text="name"
                                    value={form.nace_code}
                                    onChange={(e, { value }) => setForm({ ...form, nace_code: value })}
                                />
                            </Form.Group>
                            <Divider />
                        </div>
                    )}
                </>
            )}

            <div
                style={{
                    marginBottom: '0.5rem',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                }}
                onClick={() => setSkillsVisibility(!skillsVisibility)}
            >
                <Icon
                    name={!skillsVisibility ? 'chevron-forward-outline' : 'chevron-down-outline'}
                    style={{ marginRight: '0.5rem', cursor: 'pointer' }}
                />
                <span style={{ position: 'relative', top: '-0.2rem' }}>
                    {t('skills')}
                    <span style={{ float: 'right', fontSize: '0.9rem', paddingTop: '0.4rem' }}>
                        <Icon name="bookmark-outline" style={{ marginRight: '0.5rem' }} />
                        <span style={{ position: 'relative', top: '-0.2rem' }}>
                            {skills.filter((item) => !isEmpty(item.name)).length}
                        </span>
                    </span>
                </span>
            </div>
            <Divider />
            {skillsVisibility && (
                <div>
                    {skills.map((skill, index) => (
                        <div key={index}>
                            <Form.Group widths="equal">
                                <SuperField
                                    as="input"
                                    required
                                    placeholder={t('title')}
                                    label={t('title')}
                                    error={
                                        skills
                                            .filter((item, idx) => idx !== index)
                                            .map((item) => item.name)
                                            .includes(skill.name)
                                            ? t('skill_with_this_name_is_already_assigned')
                                            : false
                                    }
                                    value={skill.name}
                                    onChange={(e, { value }) =>
                                        setSkills((prev) =>
                                            prev.filter((item, idx) => {
                                                if (idx === index) {
                                                    item.name = value
                                                }
                                                return item
                                            })
                                        )
                                    }
                                />
                                <SuperField
                                    as="choice"
                                    type="seniority_level_types"
                                    label={t('seniority_level')}
                                    value={skill.seniority_level}
                                    onChange={(e, { value }) =>
                                        setSkills((prev) =>
                                            prev.filter((item, idx) => {
                                                if (idx === index) {
                                                    item.seniority_level = value
                                                }
                                                return item
                                            })
                                        )
                                    }
                                />
                                <Form.Field width="1" style={{ textAlign: 'center', marginTop: '2.5rem' }}>
                                    <Icon
                                        name="close-outline"
                                        style={{ cursor: 'pointer', color: 'var(--danger)' }}
                                        onClick={() => setSkills((prev) => prev.filter((item, idx) => idx !== index))}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Divider />
                        </div>
                    ))}
                    <Button
                        type="button"
                        primary
                        size="small"
                        content={t('add_skill')}
                        onClick={() => setSkills((prev) => [...prev, initialSkill])}
                    />
                    <Divider />
                </div>
            )}

            <div
                style={{
                    marginBottom: '0.5rem',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                }}
                onClick={() => setLanguagesVisibility(!languagesVisibility)}
            >
                <Icon
                    name={!languagesVisibility ? 'chevron-forward-outline' : 'chevron-down-outline'}
                    style={{ marginRight: '0.5rem', cursor: 'pointer' }}
                />
                <span style={{ position: 'relative', top: '-0.2rem' }}>
                    {t('languages')}
                    <span style={{ float: 'right', fontSize: '0.9rem', paddingTop: '0.4rem' }}>
                        <Icon name="bookmark-outline" style={{ marginRight: '0.5rem' }} />
                        <span style={{ position: 'relative', top: '-0.2rem' }}>
                            {languages.filter((item) => !isEmpty(item.language_name) && !isEmpty(item.level)).length}
                        </span>
                    </span>
                </span>
            </div>
            <Divider />
            {languagesVisibility && (
                <div>
                    {languages.map((language, index) => (
                        <div key={index}>
                            <Form.Group widths="equal">
                                <SuperField
                                    search
                                    as="choice"
                                    required
                                    customOptions={language_choices.filter(
                                        (item) =>
                                            !languages
                                                .filter((lang, idx) => idx !== index)
                                                .map((lng) => lng.language_name)
                                                .includes(item.value)
                                    )}
                                    label={t('language')}
                                    value={language.language_name}
                                    //  && languages.findIndex(item => item === language) !== index
                                    onChange={(e, { value }) =>
                                        setLanguages((prev) =>
                                            prev.filter((item, idx) => {
                                                if (idx === index) {
                                                    item.language_name = value
                                                }
                                                return item
                                            })
                                        )
                                    }
                                />
                                <SuperField
                                    search
                                    as="choice"
                                    required
                                    type="language_level_choices"
                                    label={t('level')}
                                    value={language.level}
                                    onChange={(e, { value }) =>
                                        setLanguages((prev) =>
                                            prev.filter((item, idx) => {
                                                if (idx === index) {
                                                    item.level = value
                                                }
                                                return item
                                            })
                                        )
                                    }
                                />
                                <Form.Field width="1" style={{ textAlign: 'center', marginTop: '2rem' }}>
                                    <Icon
                                        name="close"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                            setLanguages((prev) => prev.filter((item, idx) => idx !== index))
                                        }
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <SuperField
                                    as="checkbox"
                                    label={t('primary_language')}
                                    checked={language.is_primary}
                                    disabled={
                                        languages.filter((item, idx) => idx !== index && item.is_primary).length > 0
                                    }
                                    onChange={() =>
                                        setLanguages((prev) =>
                                            prev.filter((item, idx) => {
                                                if (idx === index) {
                                                    item.is_primary = !language.is_primary
                                                }
                                                return item
                                            })
                                        )
                                    }
                                />
                                <SuperField
                                    as="checkbox"
                                    label={t('native_language')}
                                    checked={language.is_native}
                                    disabled={
                                        languages.filter((item, idx) => idx !== index && item.is_native).length > 0
                                    }
                                    onChange={() =>
                                        setLanguages((prev) =>
                                            prev.filter((item, idx) => {
                                                if (idx === index) {
                                                    item.is_native = !language.is_native
                                                }
                                                return item
                                            })
                                        )
                                    }
                                />
                            </Form.Group>
                            <Divider />
                        </div>
                    ))}
                    <Button
                        type="button"
                        primary
                        size="small"
                        content={t('add_language')}
                        onClick={() => setLanguages((prev) => [...prev, initialLanguages])}
                    />
                    <Divider />
                </div>
            )}

            <div
                style={{
                    marginBottom: '0.5rem',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                }}
                onClick={() => setExperiencesVisibility(!experiencesVisibility)}
            >
                <Icon
                    name={!experiencesVisibility ? 'chevron-forward-outline' : 'chevron-down-outline'}
                    style={{ marginRight: '0.5rem', cursor: 'pointer' }}
                />
                <span style={{ position: 'relative', top: '-0.2rem' }}>
                    {t('experiences')}
                    <span style={{ float: 'right', fontSize: '0.9rem', paddingTop: '0.4rem' }}>
                        <Icon name="bookmark-outline" style={{ marginRight: '0.5rem' }} />
                        <span style={{ position: 'relative', top: '-0.2rem' }}>
                            {experiences.filter((item) => !isEmpty(item.job_title)).length}
                        </span>
                    </span>
                </span>
            </div>
            <Divider />
            {experiencesVisibility && (
                <div>
                    {experiences.map((experience, index) => (
                        <div key={index}>
                            <Form.Group widths="equal">
                                <SuperField
                                    as="input"
                                    required
                                    placeholder={t('job_title')}
                                    label={t('job_title')}
                                    value={experience.job_title}
                                    onChange={(e, { value }) =>
                                        setExperiences((prev) =>
                                            prev.filter((item, idx) => {
                                                if (idx === index) {
                                                    item.job_title = value
                                                }
                                                return item
                                            })
                                        )
                                    }
                                />
                                <SuperField
                                    as="input"
                                    placeholder={t('employer')}
                                    label={t('employer')}
                                    value={experience.employer}
                                    onChange={(e, { value }) =>
                                        setExperiences((prev) =>
                                            prev.filter((item, idx) => {
                                                if (idx === index) {
                                                    item.employer = value
                                                }
                                                return item
                                            })
                                        )
                                    }
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <SuperField
                                    as="datepicker"
                                    placeholder={t('date_from')}
                                    label={t('date_from')}
                                    value={experience.date_from}
                                    error={
                                        isFutureDate(experience.date_from)
                                            ? t('date_cant_be_future')
                                            : isDateFromValid(experience.date_from, experience.date_to)
                                            ? false
                                            : t('invalid_date_range')
                                    }
                                    onChange={(e, { value }) =>
                                        setExperiences((prev) =>
                                            prev.filter((item, idx) => {
                                                if (idx === index) {
                                                    item.date_from = value
                                                }
                                                return item
                                            })
                                        )
                                    }
                                />
                                <SuperField
                                    as="datepicker"
                                    placeholder={t('date_to')}
                                    label={t('date_to')}
                                    value={experience.date_to}
                                    error={
                                        isFutureDate(experience.date_to)
                                            ? t('date_cant_be_future')
                                            : isDateToValid(experience.date_from, experience.date_to)
                                            ? false
                                            : t('invalid_date_range')
                                    }
                                    onChange={(e, { value }) =>
                                        setExperiences((prev) =>
                                            prev.filter((item, idx) => {
                                                if (idx === index) {
                                                    item.date_to = value
                                                }
                                                return item
                                            })
                                        )
                                    }
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <SuperField
                                    as="textarea"
                                    label={t('job_description')}
                                    placeholder={t('job_description')}
                                    value={experience.description}
                                    onChange={(e, { value }) =>
                                        setExperiences((prev) =>
                                            prev.filter((item, idx) => {
                                                if (idx === index) {
                                                    item.description = value
                                                }
                                                return item
                                            })
                                        )
                                    }
                                />
                            </Form.Group>
                            <Form.Field style={{ textAlign: 'right' }}>
                                <Button
                                    type="button"
                                    content={t('remove')}
                                    style={{
                                        cursor: 'pointer',
                                        background: 'var(--danger)',
                                        color: 'var(--light)',
                                    }}
                                    onClick={() => setExperiences((prev) => prev.filter((item, idx) => idx !== index))}
                                />
                            </Form.Field>
                            <Divider />
                        </div>
                    ))}
                    <Button
                        type="button"
                        primary
                        size="small"
                        content={t('add_experience')}
                        onClick={() => setExperiences((prev) => [...prev, initialExperience])}
                    />
                    <Divider />
                </div>
            )}

            <div
                style={{
                    marginBottom: '0.5rem',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                }}
                onClick={() => setEducationsVisibility(!educationsVisibility)}
            >
                <Icon
                    name={!educationsVisibility ? 'chevron-forward-outline' : 'chevron-down-outline'}
                    style={{ marginRight: '0.5rem', cursor: 'pointer' }}
                />
                <span style={{ position: 'relative', top: '-0.2rem' }}>
                    {t('educations')}
                    <span style={{ float: 'right', fontSize: '0.9rem', paddingTop: '0.4rem' }}>
                        <Icon name="bookmark-outline" style={{ marginRight: '0.5rem' }} />
                        <span style={{ position: 'relative', top: '-0.2rem' }}>{educations.length}</span>
                    </span>
                </span>
            </div>
            <Divider />
            {educationsVisibility && (
                <div>
                    {educations.map((education, index) => (
                        <div key={index}>
                            <Form.Group widths="equal">
                                <SuperField
                                    as="input"
                                    placeholder={t('name')}
                                    label={t('name')}
                                    value={education.name}
                                    onChange={(e, { value }) =>
                                        setEducations((prev) =>
                                            prev.filter((item, idx) => {
                                                if (idx === index) {
                                                    item.name = value
                                                }
                                                return item
                                            })
                                        )
                                    }
                                />
                                <SuperField
                                    as="choice"
                                    search
                                    placeholder={t('education_type')}
                                    type="education_type_choices"
                                    label={t('education_type')}
                                    value={education.education_type}
                                    onChange={(e, { value }) =>
                                        setEducations((prev) =>
                                            prev.filter((item, idx) => {
                                                if (idx === index) {
                                                    item.education_type = value
                                                }
                                                return item
                                            })
                                        )
                                    }
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <SuperField
                                    as="input"
                                    placeholder={t('department')}
                                    label={t('department')}
                                    value={education.department}
                                    onChange={(e, { value }) =>
                                        setEducations((prev) =>
                                            prev.filter((item, idx) => {
                                                if (idx === index) {
                                                    item.department = value
                                                }
                                                return item
                                            })
                                        )
                                    }
                                />
                                <SuperField
                                    as="input"
                                    placeholder={t('field')}
                                    label={t('field')}
                                    value={education.field}
                                    onChange={(e, { value }) =>
                                        setEducations((prev) =>
                                            prev.filter((item, idx) => {
                                                if (idx === index) {
                                                    item.field = value
                                                }
                                                return item
                                            })
                                        )
                                    }
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <SuperField
                                    as="datepicker"
                                    placeholder={t('date_from')}
                                    label={t('date_from')}
                                    value={education.date_from}
                                    error={
                                        isFutureDate(education.date_from)
                                            ? t('date_cant_be_future')
                                            : isDateFromValid(education.date_from, education.date_to)
                                            ? false
                                            : t('invalid_date_range')
                                    }
                                    onChange={(e, { value }) =>
                                        setEducations((prev) =>
                                            prev.filter((item, idx) => {
                                                if (idx === index) {
                                                    item.date_from = value
                                                }
                                                return item
                                            })
                                        )
                                    }
                                />
                                <SuperField
                                    as="datepicker"
                                    placeholder={t('date_to')}
                                    label={t('date_to')}
                                    value={education.date_to}
                                    error={
                                        isFutureDate(education.date_to)
                                            ? t('date_cant_be_future')
                                            : isDateToValid(education.date_from, education.date_to)
                                            ? false
                                            : t('invalid_date_range')
                                    }
                                    onChange={(e, { value }) =>
                                        setEducations((prev) =>
                                            prev.filter((item, idx) => {
                                                if (idx === index) {
                                                    item.date_to = value
                                                }
                                                return item
                                            })
                                        )
                                    }
                                />
                            </Form.Group>

                            <Form.Field style={{ textAlign: 'right' }}>
                                <Button
                                    type="button"
                                    content={t('remove')}
                                    style={{
                                        cursor: 'pointer',
                                        background: 'var(--danger)',
                                        color: 'var(--light)',
                                    }}
                                    onClick={() => setEducations((prev) => prev.filter((item, idx) => idx !== index))}
                                />
                            </Form.Field>
                            <Divider />
                        </div>
                    ))}
                    <Button
                        type="button"
                        primary
                        size="small"
                        content={t('add_education')}
                        onClick={() => setEducations((prev) => [...prev, initialEducation])}
                    />
                    <Divider />
                </div>
            )}

            <div
                style={{
                    marginBottom: '0.5rem',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                }}
                onClick={() => setInterestsVisibility(!interestsVisibility)}
            >
                <Icon
                    name={!interestsVisibility ? 'chevron-forward-outline' : 'chevron-down-outline'}
                    style={{ marginRight: '0.5rem', cursor: 'pointer' }}
                />
                <span style={{ position: 'relative', top: '-0.2rem' }}>
                    {t('interests')}
                    <span style={{ float: 'right', fontSize: '0.9rem', paddingTop: '0.4rem' }}>
                        <Icon name="bookmark-outline" style={{ marginRight: '0.5rem' }} />
                        <span style={{ position: 'relative', top: '-0.2rem' }}>
                            {interests.filter((item) => !isEmpty(item.name)).length}
                        </span>
                    </span>
                </span>
            </div>
            <Divider />
            {interestsVisibility && (
                <div>
                    {interests.map((interest, index) => (
                        <div key={index}>
                            <Form.Group widths="equal">
                                <SuperField
                                    as="input"
                                    required
                                    placeholder={t('name')}
                                    label={t('name')}
                                    value={interest.name}
                                    onChange={(e, { value }) =>
                                        setInterests((prev) =>
                                            prev.filter((item, idx) => {
                                                if (idx === index) {
                                                    item.name = value
                                                }
                                                return item
                                            })
                                        )
                                    }
                                />
                                <Form.Field width="1" style={{ textAlign: 'center', marginTop: '2.5rem' }}>
                                    <Icon
                                        name="close-outline"
                                        style={{ cursor: 'pointer', color: 'var(--danger)' }}
                                        onClick={() =>
                                            setInterests((prev) => prev.filter((item, idx) => idx !== index))
                                        }
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Divider />
                        </div>
                    ))}
                    <Button
                        type="button"
                        primary
                        size="small"
                        content={t('add_interest')}
                        onClick={() => setInterests((prev) => [...prev, initialInterest])}
                    />
                    <Divider />
                </div>
            )}

            {parsedData?.find?.((item) => item.type === 'resume') === undefined && (
                <>
                    <Header as="h3" content={t('attach_resume')} />
                    <Divider />
                    <SuperField
                        as="input"
                        type="file"
                        onChange={(event) => {
                            if (event.target.files.length > 0) {
                                setCV(event.target.files[0])
                            }
                        }}
                    />
                </>
            )}

            <Header as="h3" content={t('labels')} />
            {labels.map((label) => (
                <Label
                    style={{
                        marginTop: '3px',
                        color: 'white',
                        backgroundColor: label.color === '' ? '' : label.color,
                    }}
                    key={label.id}
                >
                    {label.name}
                    <Icon
                        name="close-outline"
                        style={{ marginLeft: '1rem', cursor: 'pointer' }}
                        onClick={() => onLabelRemove(label.id)}
                    />
                </Label>
            ))}
            <SuperDuperModal
                trigger={
                    <Label basic size="tiny" style={{ cursor: 'pointer' }}>
                        <Icon name="add-outline" />
                    </Label>
                }
                content={<LabelCandidateForm labels={labels} setLabels={setLabels} />}
            />

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit disabled={isProcessing || !isFormValid()} loading={isProcessing} />
            </Form.Field>
        </Form>
    )
}

export default CandidateFormx
