import React from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { routes } from '@routes'
// components
import Icon from '@components/Icon'
import ListView from '@components/ListView'
import CanView from '@components/perms/CanView'
import SuperField from '@components/forms/SuperField'
import LabelsList from '@components/lists/LabelsList'
import BreadcrumbNav from '@components/BreadcrumbNav'
// custom components
import DocumentForm from './DocumentForm'
import DocumentCreateForm from './DocumentCreateForm'

const DocumentTemplates = () => {
    const { t } = useTranslation()

    const modelTerms = {
        Contract: t('employment_contract'),
        BusinessContract: t('business_contract'),
        ContractTermination: t('termination'),
        ForeignerProcess: t('foreigners'),
    }

    const getSource = (fullSource) => {
        let splitSource = fullSource.split('.')

        return modelTerms[splitSource[1]]
    }

    const onDelete = async (document, setData, setTotal) => {
        const request = await requests.patch(API.TEMPLATING + document.id + '/', {
            is_archived: !document.is_archived,
        })
        if (request.status === 200) {
            setData((prev) =>
                prev.map((item) => {
                    if (item.id === document.id) {
                        item = request.response
                    }

                    return item
                })
            )
        }
    }

    return (
        <CanView permissions={['templating.c_view_documents']}>
            <BreadcrumbNav items={[{ name: t('templates'), icon: 'document-text-outline', href: '' }]} />
            <ListView
                as="table"
                allowSearch
                actionsCellWidth="2"
                endpoint={API.TEMPLATING}
                initialFilters={{
                    is_archived: false,
                    source: '',
                    labels: [],
                }}
                listAdditionActions={(data, setData, total, setTotal, response) => [
                    {
                        as: 'filter',
                        index: 0,
                        name: t('active'),
                        filters: {
                            is_archived: false,
                        },
                    },
                    {
                        as: 'filter',
                        index: 1,
                        name: t('archived'),
                        filters: {
                            is_archived: true,
                        },
                    },
                    {
                        as: 'filter',
                        index: 2,
                        name: t('all'),
                        filters: {
                            is_archived: null,
                        },
                    },
                ]}
                renderFilterFields={(filters, setFilters) => (
                    <>
                        <SuperField
                            as="choice-select"
                            search
                            multiple
                            label={t('tags')}
                            text="name"
                            value={filters.labels}
                            endpoint={API.TEMPLATES + 'labels/'}
                            onChange={(e, { value }) => setFilters((prev) => ({ ...prev, labels: value }))}
                        />

                        <SuperField
                            as="choice-select"
                            label={t('data_source')}
                            value={filters.source}
                            customOptions={[
                                { key: 1, text: t('employment_contract'), value: 'contracts.Contract' },
                                { key: 2, text: t('business_contract'), value: 'contracts.BusinessContract' },
                                { key: 3, text: t('termination'), value: 'contracts.ContractTermination' },
                                { key: 4, text: t('foreigners'), value: 'foreigners.ForeignerProcess' },
                            ]}
                            onChange={(e, { value }) => setFilters((prev) => ({ ...prev, source: value }))}
                        />
                    </>
                )}
                tableHeaders={[
                    { title: t('title') },
                    { title: t('tags') },
                    { title: t('data_source') },
                    { title: t('status') },
                ]}
                renderCells={(template) => [
                    { content: template.title },
                    {
                        content: (
                            <LabelsList
                                rowID={template.id}
                                labels={template?.labels || []}
                                endpoint={API.TEMPLATING}
                                endpointLabels={API.TEMPLATES + 'labels/'}
                                label_key="labels"
                            />
                        ),
                    },
                    { content: getSource(template.source) },
                    {
                        content: (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Icon
                                    name="ellipse"
                                    style={{
                                        color: template.is_archived === false ? 'var(--success)' : 'var(--danger)',
                                        marginRight: '0.5rem',
                                    }}
                                />
                                <span> {template.is_archived === false ? t('active') : t('archived')} </span>
                            </div>
                        ),
                    },
                ]}
                actions={[
                    {
                        as: 'modal',
                        type: 'add',
                        modalSize: 'tiny',
                        name: t('create_template'),
                        modal: <DocumentCreateForm />,
                        permissions: ['templating.c_manage_documents'],
                    },
                    {
                        as: 'link',
                        type: 'edit',
                        name: t('edit'),
                        customIconColor: 'var(--dark)',
                        redirect: (item) => routes.SETTINGS + 'templates/' + item.id,
                        // modal: (item, setData) => <DocumentForm document={item} setData={setData} />,
                        permissions: ['templating.c_manage_documents'],
                    },
                    {
                        name: t('archive'),
                        type: 'delete',
                        as: 'delete',
                        text: t('archive_template'),
                        customDelete: (item, setData, setTotal) => onDelete(item, setData, setTotal),
                        permissions: ['templating.c_manage_documents'],
                    },
                ]}
            />
        </CanView>
    )
}

export default DocumentTemplates
