import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import Invoice from './documents/Invoice';
import { Segment, Card, Grid, Icon, Header } from 'semantic-ui-react';


const CreateLinkedDocument = ({ connection, journal, onClose, selectedInvoice, setSize }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)
    const [invoice] = useState(selectedInvoice)
    const [data] = useState({
        invoice_id: selectedInvoice.id,
        is_issued: selectedInvoice.is_issued,
    })
    const [documentInstance, setDocumentInstance] = useState(null)
    const [items, setItems] = useState([])

    const invoiceCleanup = () =>  {
        let invoiceCopy = invoice
        delete invoiceCopy.account
        delete invoiceCopy.approval
        delete invoiceCopy.created_at
        delete invoiceCopy.date_approved
        delete invoiceCopy.exchange_date
        delete invoiceCopy.invoice_type
        delete invoiceCopy.invoice_type_display
        delete invoiceCopy.is_issued
        delete invoiceCopy.liked_documents
        delete invoiceCopy.modified_at
        delete invoiceCopy.note
        delete invoiceCopy.number
        delete invoiceCopy.payment_is_approved
        delete invoiceCopy.payment_is_verified
        delete invoiceCopy.payments
        delete invoiceCopy.price
        delete invoiceCopy.price_display
        delete invoiceCopy.price_to_pay
        delete invoiceCopy.ref_invoice
        delete invoiceCopy.ref_invoice_number
        delete invoiceCopy.scan
        delete invoiceCopy.split_ratio
        delete invoiceCopy.status
        delete invoiceCopy.status_display
        delete invoiceCopy.taxes
        delete invoiceCopy.variable_symbol
        delete invoiceCopy.group
        delete invoiceCopy.advance
        delete invoiceCopy.id
        delete invoiceCopy.date_due
        delete invoiceCopy.date_issue
        delete invoiceCopy.date_paid
        delete invoiceCopy.date_supply

        return invoiceCopy
    }

    const handleDocCreate = async (document) => {
        setIsProcessing(true)
        let invoice_type = 1
        if (document === "credit_note") invoice_type = 4
        if (document === "debit_note") invoice_type = 5

        const request = await requests.post(API.INVOICES + "?connection=" + connection.connection_id + "&account_ids=" + journal.id, {
            ...invoiceCleanup(),
            invoice_type: invoice_type,
            status: 4,
            is_issued: data.is_issued,
            ref_invoice: data.invoice_id,
            account: journal.account_id
        })
        
        if( request.status === 201 ){
            // fetch items
            const requestItems = await requests.get(API.INVOICES + "items/?connection=" + connection.connection_id + "&account_ids=" + journal.id + "&invoice__id=" + data.invoice_id)
            if( requestItems.status === 200 ){
                if( requestItems.response.length > 0 ){
                    let itemList = []
                    for (let i = 0; i < requestItems.response.length; i++) {
                        itemList.push({
                            id: 0,
                            invoice: request.response.id,
                            service_type: requestItems.response[i].service_type,
                            amount: requestItems.response[i].amount,
                            code: requestItems.response[i].code,
                            name: requestItems.response[i].name,
                            unit: requestItems.response[i].unit,
                            unit_price: requestItems.response[i].unit_price > 0 
                                ? document === "credit_note" 
                                    ? requestItems.response[i].unit_price * (-1) 
                                    : requestItems.response[i].unit_price
                                : "",
                            tax_rate: requestItems.response[i].tax_rate,
                            discount: requestItems.response[i].discount,
                            note: requestItems.response[i].note,
                            processing: false,
                            error: null
                        });
                        
                    }
                    setItems(itemList)
                }
            }

            setDocumentInstance(request.response)
            setSize("large")
        }
        setIsProcessing(false)
    }

    return (
        <>
            { documentInstance === null 
                ? 
                    <Segment
                        loading={isProcessing}
                        style={{ 
                            padding: 0, 
                            margin: 0, 
                            background: "transparent", 
                            boxShadow: "none", 
                            border: "none", 
                            paddingTop: 0,
                            paddingBottom: 0,
                        }}
                    >
                        <Grid>
                            <Grid.Row>
                                <Grid.Column textAlign="center">
                                    <Header as="h2" content={ t('choose_document_type_to_link') }/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column
                                    onClick={() => handleDocCreate('credit_note')}
                                    className="toggle-button"
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    <Card fluid style={{ borderRadius: 0 }}>
                                        <Card.Content as="h2">
                                            <Icon name="document-text-outline" style={{ marginRight: "0.5rem" }}/>
                                                { t('credit_note') }
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                                <Grid.Column
                                    onClick={() => handleDocCreate('debit_note')}
                                    className="toggle-button"
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    <Card fluid style={{ borderRadius: 0 }}>
                                        <Card.Content as="h2">
                                            <Icon name="document-text-outline" style={{ marginRight: "0.5rem" }}/>
                                                { t('debit_note') }
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                : 
                <Invoice 
                    document={data.is_issued ? "sent_invoice" : "recieved_invoice"}
                    invoice={documentInstance}
                    connection={connection}
                    journal={journal}
                    onClose={onClose}
                    isUpdate={true}
                    invoiceItems={items}
                />
            }
        </>
    );
};

export default CreateLinkedDocument;